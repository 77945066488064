import clsx from "clsx"
import type { AnchorHTMLAttributes, HTMLAttributes, ReactNode } from "react"
import { Link } from "react-router-dom"

import type { MinimumIdentifiableEntity } from "../types/entities"
import { getGlobalEntityLinkAttrs } from "../utils/global-routes"
import type { GlobalEntityPossibleTitleKeys } from "../utils/global-titles"
import { GlobalEntityTitle } from "./global-entity-title"

export const GlobalEntityLink = <E extends MinimumIdentifiableEntity>({
	entity,
	className,
	target,
	subRoute,
	defaultLabel = "-",
	labelType = "entityLabel",
	navigable = true,
	...rest
}: {
	entity: E
	className?: string
	target?: AnchorHTMLAttributes<typeof Link>["target"]
	subRoute?: string
	defaultLabel?: string
	labelType?: GlobalEntityPossibleTitleKeys
	children?: ReactNode
	navigable?: boolean
} & HTMLAttributes<HTMLSpanElement>) => {
	const { to } = getGlobalEntityLinkAttrs<E>(entity, subRoute)

	// do not override provided children comp at all (allow to override default labelType)
	const titleComp =
		"children" in rest ? (
			rest.children
		) : (
			<GlobalEntityTitle entity={entity} defaultLabel={defaultLabel} labelType={labelType} />
		)

	if (navigable)
		return (
			<Link className={clsx(className, "hover:underline")} target={target} to={to}>
				{titleComp}
			</Link>
		)

	return titleComp
}
