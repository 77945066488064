import { useMemo, useState } from "react"

import type { SelectProps } from "@components/select"
import { Select } from "@components/select"
import { useSearchLocalizationsQuery } from "@graphql/apollo"
import { useRevampedDebounce } from "@hooks/use-debounce"

import type { LocalizationKind, SearchLocalizationsQuery } from "../../../graphql/types"
import type { Unpack } from "../../../utils/types"

export type LocalizationOption = Unpack<SearchLocalizationsQuery["localizations"]["nodes"]>

const noFilter = () => true

export function SelectLocalization<IsMulti extends boolean = false>(
	props: {
		countryId?: string
		localizationKind?: LocalizationKind[]
	} & SelectProps<LocalizationOption, IsMulti>,
) {
	const { isLoading, invalid, countryId, localizationKind, ...rest } = props

	const [name, setName] = useState("")
	const debouncedName = useRevampedDebounce(name)

	const { data, loading } = useSearchLocalizationsQuery({
		fetchPolicy: "cache-and-network",
		skip: !debouncedName,
		variables: debouncedName
			? {
					name: debouncedName,
					countryId,
					localizationKind,
				}
			: undefined,
	})

	const options = useMemo(() => {
		if (data?.localizations) return data.localizations.nodes
		return []
	}, [data])

	return (
		<Select
			{...rest}
			invalid={invalid}
			isLoading={loading || isLoading}
			options={options}
			getOptionValue={({ id }) => id}
			getOptionLabel={({ name, type, code, parent }) =>
				`${[name, code, type].filter(Boolean).join(", ")}${parent ? ` (${parent.name}, ${parent.type})` : ""}`
			}
			filterOption={noFilter}
			onInputChange={(value) => {
				setName(value)
			}}
		/>
	)
}
