import type { Params } from "react-router-dom"

import { getGlobalEntityTitleAttrs } from "@utils/global-titles"
import { BreadCrumbLabel } from "../../components/breadcrumbs"
import { useSeriesEntity } from "./hooks/series"

export const SeriesBreadCrumbTitle = ({ match }: { match: Params<string> }) => {
	if (!match.series) throw new Error("SeriesBreadCrumbTitle:requirements not met")
	const { series } = useSeriesEntity(match.series)
	const titleAttrs = series ? getGlobalEntityTitleAttrs(series) : undefined
	return <BreadCrumbLabel label={titleAttrs?.entityLabel} copyable />
}
