import type { Language } from "../../graphql/types"
import type { BrandOption } from "./components/select-brand"
import type { CountryOption } from "./components/select-country"
import type { LocaleOption } from "./components/select-locale"
import { useViewerPreferences } from "./viewer"

export function useDefaultBrand(): (BrandOption & { language?: Language | null }) | undefined {
	const preferences = useViewerPreferences()
	return preferences?.defaultBrand ?? undefined
}

export function useDefaultCountry(): (CountryOption & { language?: Language | null }) | undefined {
	const preferences = useViewerPreferences()
	return preferences?.defaultCountry ?? undefined
}

export function useDefaultLocale(): (LocaleOption & { language?: Language | null }) | undefined {
	const preferences = useViewerPreferences()
	return preferences?.defaultLocale ?? undefined
}

export function useDefaultLanguage() {
	const defaultBrand = useDefaultBrand()
	const defaultCountry = useDefaultCountry()
	const defaultLocale = useDefaultLocale()

	if (defaultBrand?.language) return defaultBrand.language
	if (defaultLocale?.language) return defaultLocale.language
	if (defaultCountry?.language) return defaultCountry.language

	return undefined
}
