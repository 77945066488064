import type { Params } from "react-router-dom"

import { BreadCrumbLabel } from "../../components/breadcrumbs"
import { useFestivalAwardEntity } from "./hooks/festival-award"

export const FestivalAwardBreadCrumbTitle = ({
	match,
}: {
	match: Params<string>
}) => {
	const festivalAwardRef = match.award
	if (!festivalAwardRef) throw new Error("FestivalAwardBreadCrumbTitle:requirements not met")

	const { titleAttrs } = useFestivalAwardEntity(festivalAwardRef)
	return <BreadCrumbLabel label={titleAttrs?.entityLabel} copyable />
}

export default FestivalAwardBreadCrumbTitle
