import { useMemo } from "react"
import { matchRoutes, useLocation } from "react-router-dom"

import { BreadCrumbLabel, BreadcrumbItem } from "@components/breadcrumbs"

import type { RouteMatchDefinition } from "./breadcrumbs-routes"
import { routes } from "./breadcrumbs-routes"

export function Breadcrumbs() {
	const location = useLocation()

	const matchedRoutes: RouteMatchDefinition[] | null = useMemo(() => matchRoutes(routes, location.pathname), [location])

	if (!matchedRoutes) return null

	return (
		<div className="pl-4 pr-4 h-full flex flex-nowrap overflow-x-auto max-w-[75vw] items-center shadow-inner">
			{matchedRoutes.map((matchedRoute, index) => {
				const { params, pathname, route } = matchedRoute
				const { label, textOnly, canNavigate, copyable: pCopyable, redirectToFn } = route

				if (!label) return null

				const isNotExact = location.pathname !== pathname
				const isNavigable = isNotExact && pathname && !textOnly

				let href: string | undefined
				if (isNavigable) href = pathname
				if (redirectToFn) href = redirectToFn(matchedRoute)
				const allowNavigate = canNavigate?.({ match: params }) ?? true
				const copyable = typeof pCopyable === "function" ? pCopyable({ match: params }) : pCopyable

				const fLabel = typeof label === "function" ? label({ match: params, copyable }) : label

				return (
					<BreadcrumbItem key={index} href={allowNavigate ? href : undefined} isRoot={!index}>
						{typeof fLabel === "string" ? <BreadCrumbLabel label={fLabel} copyable={copyable} /> : fLabel}
					</BreadcrumbItem>
				)
			})}
		</div>
	)
}
