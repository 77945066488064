import type { Params } from "react-router-dom"

import { BreadCrumbLabel } from "../../components/breadcrumbs"
import { useFestivalEntity } from "./hooks/festival"

export const FestivalBreadCrumbTitle = ({
	match,
}: {
	match: Params<string>
}) => {
	if (!match.festival) throw new Error("FestivalBreadCrumbTitle:requirements not met")

	const { titleAttrs } = useFestivalEntity(match.festival)
	return <BreadCrumbLabel label={titleAttrs?.label} copyable />
}

export default FestivalBreadCrumbTitle
