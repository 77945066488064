import type { OperationVariables, QueryResult } from "@apollo/client"
import { useDefaultBrand, useDefaultLocale } from "../pages/shared/preferences"

import type * as ApolloReactHooks from "../utils/custom-apollo-hooks"

const useInjectGlobalVariables = <TData, TVars extends OperationVariables>(
	hook: (
		options: ApolloReactHooks.QueryHookOptions<TData, TVars> & ({ variables: TVars; skip?: boolean } | { skip: boolean }),
	) => QueryResult<TData, TVars>,
	// override of particular global variables
	options: Omit<ApolloReactHooks.QueryHookOptions<TData, TVars>, "variables"> & {
		variables: Omit<
			TVars,
			| "brandId"
			| "brandIds"
			| "locales"
			| "includeBrandData"
			| "includeLocalesData"
			| "includeLocaleData"
			| "includeBrandsData"
		> & {
			brandId?: string
			brandIds?: string[]
			locales?: string[]
			includeBrandData?: boolean
			includeBrandsData?: boolean // todo: harmonize includeBrandData and includeBrandsData
			includeLocaleData?: boolean
			includeLocalesData?: boolean // todo: harmonize includeLocaleData and includeLocalesData
		}
	},
) => {
	const defaultBrand = useDefaultBrand()
	const defaultLocale = useDefaultLocale()

	const hasDefaultBrand = Boolean(defaultBrand?.id)
	const hasDefaultLocale = Boolean(defaultLocale?.id)

	return hook({
		fetchPolicy: "cache-and-network",
		...(options ?? {}),
		variables: {
			brandId: defaultBrand?.id ?? "",
			brandIds: hasDefaultBrand ? [defaultBrand?.id] : undefined,
			locales: hasDefaultLocale ? [defaultLocale?.id] : undefined,
			includeBrandData: hasDefaultBrand,
			includeBrandsData: hasDefaultBrand,
			includeLocalesData: hasDefaultLocale,
			...(options.variables ?? {}),
		} as unknown as TVars,
	})
}

export default useInjectGlobalVariables
