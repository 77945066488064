import clsx from "clsx"
import { useEffect, useRef, useState } from "react"
import Creatable from "react-select/creatable"

import styles from "./select.module.css"

export interface TagInputProps {
	invalid?: boolean
	value?: string[]
	onBlur?: () => void
	onFocus?: () => void
	onChange?: (value: string[]) => void
}

export function TagInput(props: TagInputProps) {
	const { invalid = false, value: initialValues, onBlur, onFocus, onChange } = props

	const [input, setInput] = useState("")
	const [values, setValues] = useState<string[]>(initialValues ?? [])

	const onChangeRef = useRef(onChange)

	function addValue(value: string) {
		if (value) {
			const _value = value.trim()
			setInput("")
			if (!values.includes(_value)) {
				setValues([...values, _value])
			}
		}
	}

	useEffect(() => {
		setValues(initialValues ?? [])
	}, [initialValues])

	useEffect(() => {
		if (onChangeRef.current && values) {
			onChangeRef.current(values)
		}
	}, [values])

	return (
		<Creatable
			classNamePrefix="react-select"
			className={clsx(styles.select, {
				[styles.select__invalid]: invalid,
			})}
			components={{
				DropdownIndicator: null,
			}}
			isClearable
			isMulti
			menuIsOpen={false}
			inputValue={input}
			value={Array.from(values, (value) => ({
				value,
				label: value,
			}))}
			onInputChange={(value) => {
				setInput(value)
			}}
			onKeyDown={(event) => {
				if (event.key === "Enter" || event.key === "Tab" || event.key === ",") {
					event.preventDefault()
					addValue(input)
				} else if (event.key === "Backspace") {
					if (input.length === 0) {
						event.preventDefault()
						setValues(values.slice(0, -1))
					}
				}
			}}
			onBlur={() => {
				addValue(input)
				if (onBlur) {
					onBlur()
				}
			}}
			onFocus={onFocus}
			onChange={(_value, meta) => {
				if (meta.action === "clear") {
					setValues([])
				} else if (meta.action === "remove-value") {
					const index = values.indexOf(meta.removedValue?.value)
					setValues(values.slice(0, index).concat(values.slice(index + 1)))
				}
			}}
		/>
	)
}
