import { useGetEpisodeMetadataQuery } from "@graphql/apollo"
import type { NoEmpty } from "@utils/types"

export const useEpisodeEntity = (episodeRef: string) => {
	if (!episodeRef) throw new Error("useEpisodeEntity: mising ref parameter")
	const { data, loading } = useGetEpisodeMetadataQuery({
		fetchPolicy: "cache-and-network",
		variables: {
			ref: episodeRef,
		},
	})
	const entity = data?.entityByRef
	const seriesEpisode = entity as NoEmpty<typeof entity>
	const isCorrectEntity = seriesEpisode?.__typename === "SeriesEpisode"
	return {
		loading,
		episode: isCorrectEntity ? seriesEpisode : undefined,
	}
}
