import clsx from "clsx"
import { forwardRef, useEffect, useState } from "react"
import type { SingleValue } from "react-select"
import type { Currency } from "../graphql/types"
import { type CurrencyOption, SelectCurrency } from "../pages/shared/components/select-currency"
import { formatCurrencySymbol } from "../utils/format-currency-symbol"
import { Input, type InputProps as InputPropsBase } from "./input"
import { createOption } from "./select"

export type BudgetType = { currency?: CurrencyOption | null; value?: number | null } | undefined | null

export type InputProps = {
	value: BudgetType
	onChange: (value: BudgetType) => void
} & Omit<InputPropsBase, "value" | "onChange">

export type InputCurrencyProps = InputProps & {
	defaultCurrency?: Currency
	currency?: CurrencyOption
	min?: number | string
	max?: number | string
	step?: number | string
	onCurrencyChange?: (currency: SingleValue<CurrencyOption>) => void
}

export const InputCurrency = forwardRef<HTMLInputElement, InputCurrencyProps>(function InputCurrency(props, ref) {
	const { onChange, className, value, defaultCurrency, ...rest } = props

	const [selectedCurrency, setSelectedCurrency] = useState<CurrencyOption | undefined | null>(
		value?.currency ?? (rest.required && defaultCurrency ? createOption(defaultCurrency) : null),
	)
	const [amount, setAmount] = useState(value?.value)

	useEffect(() => {
		onChange?.({
			value: amount,
			currency: selectedCurrency,
		})
	}, [amount, selectedCurrency, onChange])
	useEffect(() => {
		setAmount(value?.value)
	}, [value])

	return (
		<div className="flex gap-1">
			<Input
				ref={ref}
				{...rest}
				value={amount ?? ""}
				className={clsx("w-full", className)}
				type="number"
				onChange={(event) => {
					const parsed = Number.parseFloat(event.target.value)
					setAmount(Number.isNaN(parsed) ? undefined : parsed)
				}}
				step=".01"
			/>
			<SelectCurrency
				className="h-full shrink-0"
				required={rest.required}
				isClearable={!rest.required}
				getOptionLabel={(option) => `${option.value} ${formatCurrencySymbol(option.value)}`}
				value={selectedCurrency}
				isDisabled={rest.disabled}
				onChange={(value) => setSelectedCurrency(value)}
			/>
		</div>
	)
})
