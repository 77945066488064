import clsx from "clsx"
import type { InputHTMLAttributes } from "react"

export type ToggleButtonColor = "primary" | "danger" | "success" | "warning"

export interface ToggleProps extends InputHTMLAttributes<HTMLInputElement> {
	invalid?: boolean
	isEmphasized?: boolean
	color?: ToggleButtonColor
}

export function Toggle(props: ToggleProps) {
	const {
		checked,
		disabled,
		children,
		readOnly = false,
		name,
		tabIndex,
		onBlur,
		onChange,
		onFocus,
		isEmphasized = true,
		className,
		title,
		color = "primary",
	} = props
	return (
		<label className={clsx("inline-flex items-center group h-8", className)} title={title}>
			<input
				type="checkbox"
				name={name}
				checked={checked}
				disabled={disabled}
				readOnly={readOnly}
				onBlur={onBlur}
				onChange={onChange}
				onFocus={onFocus}
				tabIndex={tabIndex}
				className="sr-only peer"
			/>
			<span
				className={clsx(
					"inline-block h-5 w-10 rounded-full relative",
					"transition-colors",
					"peer-focus-visible:ring-offset-2 peer-focus-visible:ring-2",
					{
						"hover:cursor-pointer": !disabled,
						"hover:cursor-not-allowed": disabled,
						"opacity-35": disabled,
					},
					color === "primary"
						? [
								"peer-focus-visible:ring-blue-500",
								checked
									? disabled
										? "bg-gray-300"
										: isEmphasized
											? "bg-blue-500 group-hover:bg-blue-600"
											: "bg-gray-500 group-hover:bg-gray-600"
									: "bg-gray-200",

								disabled
									? "text-gray-300"
									: checked
										? isEmphasized
											? "text-blue-500 group-hover:text-blue-600"
											: "text-gray-500 group-hover:text-gray-600"
										: "text-gray-400 group-hover:text-gray-500",
							]
						: undefined,
					color === "success"
						? [
								"bg-emerald-400",
								{
									"hover:bg-emerald-500": !disabled,
								},
							]
						: undefined,
					color === "danger"
						? [
								"bg-red-500 ",
								{
									"hover:bg-red-600": !disabled,
								},
							]
						: undefined,
				)}
			>
				<span
					className={clsx(
						"block h-full aspect-square absolute top-0 left-0",
						"rounded-full bg-white border-2  border-current",
						"transition",
						checked ? "translate-x-5" : "translate-x-0",
						color === "success"
							? [
									"border-emerald-400",
									{
										"hover:border-green-500": !disabled,
									},
								]
							: undefined,
						color === "danger"
							? [
									"border-red-500",
									{
										"hover:border-red-600": !disabled,
									},
								]
							: undefined,
					)}
				/>
			</span>
			{children && (
				<span
					className={clsx(
						"ml-2 text-sm transition-colors",
						disabled ? "text-gray-400" : "text-gray-600 group-hover:text-gray-700",
					)}
				>
					{children}
				</span>
			)}
		</label>
	)
}
