import type { SelectProps } from "@components/select"
import { Select } from "@components/select"
import { WidgetType } from "@graphql/types"

export type WidgetTypeOption = {
	label: string
	value: WidgetType
}

const options = Object.values(WidgetType).map((type) => ({
	value: type,
	label: type,
}))

export function SelectWidgetType(props: SelectProps<WidgetTypeOption>) {
	return <Select<WidgetTypeOption> {...props} options={options} />
}
