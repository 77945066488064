import clsx from "clsx"
import type { ReactElement } from "react"
import { cloneElement } from "react"

export interface IconProps {
	children: ReactElement
	size?: "S" | "M" | "L" | "auto"
	className?: string
	disabledClasses?: string[]
	disabled?: boolean
	animate?: boolean
}

export type IconPropsWithoutChildren = Omit<IconProps, "children">

export function Icon(props: IconProps) {
	const { children, className, size = "S", disabled, disabledClasses } = props
	const dynamicClassName = clsx(className, children.props.className, "inline-block fill-current select-none", {
		"w-4 h-4": size === "S",
		"w-6 h-6": size === "M",
		"w-9 h-9": size === "L",
		"hover:cursor-not-allowed": disabled,
	})
	const finalClassName =
		disabledClasses && (disabledClasses?.length ?? 0) > 0
			? disabledClasses.reduce((acc, dc) => acc.replaceAll(dc, ""), dynamicClassName)
			: dynamicClassName

	return cloneElement(children, {
		contentEditable: false,
		className: finalClassName,
	})
}
