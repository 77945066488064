import clsx from "clsx"
import type { HTMLAttributes, ReactNode, TdHTMLAttributes } from "react"
import { Children, createContext, forwardRef, useContext, useEffect, useRef, useState } from "react"

import { ArrowDownwardIcon } from "@icons/arrow-downward-icon"

import { getGlobalEntityLinkAttrs } from "@utils/global-routes"
import type { GlobalEntityPossibleTitleKeys } from "@utils/global-titles"
import type { MinimumIdentifiableEntity } from "bocms/src/types/entities"
import { CopyButton, CopyableGlobalEntityTitle } from "./copy-button"
import { ExternalLink } from "./external-link"
import { ProgressBar } from "./progress-bar"

export interface TableProps extends Omit<HTMLAttributes<HTMLTableElement>, "onDrop" | "onHover"> {
	loading?: boolean
	pending?: boolean
	borderBottom?: boolean
	layout?: "fixed" | "auto"
	tableTitle?: ReactNode
}

type TableHeadProps = HTMLAttributes<HTMLTableSectionElement>

type TableBodyProps = HTMLAttributes<HTMLElement>
export interface TableRowProps extends HTMLAttributes<HTMLTableRowElement> {
	highlight?: boolean
}

interface TableCellProps extends TdHTMLAttributes<HTMLTableCellElement> {
	align?: "left" | "center" | "right"
	truncate?: boolean
}

interface TableActionsCellProps {
	children?: ReactNode
	className?: string
	contentClassName?: string
	gap?: boolean
}

interface TableSortLabelProps extends HTMLAttributes<HTMLSpanElement> {
	active?: boolean
	direction?: "ASC" | "DESC"
}

interface TableState {
	cols: number
	setCols: (count: number) => void
}

const TableColsContext = createContext<TableState>({
	cols: 1,
	setCols: () => {},
})

export function Table(props: TableProps) {
	const {
		children,
		className,
		loading = false,
		pending = false,
		borderBottom = true,
		layout = "fixed",
		tableTitle,
		...rest
	} = props

	const [cols, setCols] = useState(1)

	return (
		<TableColsContext.Provider value={{ cols, setCols }}>
			<div className={clsx("overflow-x-auto", className)}>
				{tableTitle ? <div className="mb-2">{tableTitle}</div> : null}
				{(loading || pending) && (
					<div className="relative">
						<div className="absolute w-full">
							<ProgressBar isIndeterminate color={loading ? "info" : "secondary"} />
						</div>
					</div>
				)}
				<table
					className={clsx(
						"border-t  border-gray-200",
						{ "border-b": borderBottom },
						{
							"table-fixed": layout === "fixed",
							"table-auto": layout === "auto",
						},
						"w-full",
					)}
					{...rest}
				>
					{children}
				</table>
			</div>
		</TableColsContext.Provider>
	)
}

export function TableHead(props: TableHeadProps) {
	const { children, className, ...rest } = props
	const table = useContext(TableColsContext)

	const countRef = useRef(0)

	useEffect(() => {
		let columns = 0
		Children.forEach(children, (child) => {
			if (child) columns++
		})

		if (columns !== countRef.current) {
			table.setCols(columns)
			countRef.current = columns
		}

		return () => {
			table.setCols(countRef.current)
		}
	}, [children, table])

	return (
		<thead className={className} {...rest}>
			<TableRow>{children}</TableRow>
		</thead>
	)
}

export function TableHeadCell(props: TableCellProps) {
	const { align = "left", children, truncate, className, ...rest } = props
	return (
		<th
			className={clsx(className, "p-2 text-sm", "bg-gray-100", "font-semibold", {
				"text-right": align === "right",
				"text-left": align === "left",
				"text-center": align === "center",
				truncate,
			})}
			{...rest}
		>
			{children}
		</th>
	)
}

export function TableBody(props: TableBodyProps) {
	const { children, ...rest } = props
	return <tbody {...rest}>{Children.count(children) === 0 ? <NoData /> : children}</tbody>
}

export const TableRow = forwardRef<HTMLTableRowElement, TableRowProps>(function TableRow(props, ref) {
	const { children, className, highlight = false, ...rest } = props
	return (
		<tr ref={ref} className={clsx(className, { "bg-amber-100": highlight })} {...rest}>
			{children}
		</tr>
	)
})

export function TableCell(props: TableCellProps) {
	const { align, children, className, truncate, ...rest } = props
	return (
		<td
			className={clsx(className, "p-2 text-sm", "border-t border-gray-200", {
				"text-right": align === "right",
				"text-left": align === "left",
				"text-center": align === "center",
				truncate,
			})}
			{...rest}
		>
			{children}
		</td>
	)
}

export const LinkableTableCell = ({ url }: { url: string }) => {
	const [visible, setVisible] = useState(false)
	return (
		<TableCell truncate title={url}>
			<div
				className="flex flex-row gap-2 items-center"
				onMouseOver={() => {
					setVisible(true)
				}}
				onMouseOut={() => {
					setVisible(false)
				}}
			>
				<ExternalLink url={url} className="truncate" />
				<CopyButton
					value={url}
					className={clsx({
						visible: visible,
						hidden: !visible,
					})}
				/>
			</div>
		</TableCell>
	)
}

export const LinkableTableCellEntity = ({
	entity,
	labelType = "entityLabel",
	className,
	contentClassName,
}: {
	entity: MinimumIdentifiableEntity
	labelType?: GlobalEntityPossibleTitleKeys
	className?: string
	contentClassName?: string
}) => {
	const title = getGlobalEntityLinkAttrs(entity)[labelType]

	return (
		<TableCell truncate className={className} title={title}>
			<CopyableGlobalEntityTitle
				entity={entity}
				labelType={labelType}
				className={clsx("inline-flex items-center", contentClassName)}
			/>
		</TableCell>
	)
}

export function TableSortLabel(props: TableSortLabelProps) {
	const { active = false, children, direction, ...rest } = props

	const goesUp = (active && direction === "ASC") || !active

	return (
		<span {...rest} className="cursor-pointer">
			{children}
			{active && (
				<ArrowDownwardIcon
					className={clsx("ml-1 align-text-bottom", "transform transition-transform", goesUp ? "rotate-180" : "rotate-0")}
				/>
			)}
		</span>
	)
}

export function TableActionsCell({ children, className, contentClassName, gap = true }: TableActionsCellProps) {
	return (
		<TableCell className={className}>
			<div
				className={clsx("flex justify-end", { "gap-1": gap }, contentClassName)}
				onClick={(event) => event.stopPropagation()}
			>
				{children}
			</div>
		</TableCell>
	)
}

function NoData() {
	const { cols } = useContext(TableColsContext)
	return (
		<TableRow>
			<TableCell align="center" colSpan={cols}>
				No data found.
			</TableCell>
		</TableRow>
	)
}
