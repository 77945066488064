import type { ForwardedRef, RefObject } from "react"
import { useLayoutEffect, useRef } from "react"

export function useForwardedRef<T>(ref: ForwardedRef<T>): RefObject<T> {
	const innerRef = useRef<T>(null)

	useLayoutEffect(() => {
		if (ref) {
			if (typeof ref === "function") ref(innerRef.current)
			else ref.current = innerRef.current
		}
	}, [ref])

	return innerRef
}
