import type { IconPropsWithoutChildren } from "@components/icon"
import { Icon } from "@components/icon"

export function GraphIcon(props: IconPropsWithoutChildren) {
	return (
		<Icon {...props}>
			<svg viewBox="0 0 24 24">
				<path d="M18.9,15.845a2.955,2.955,0,0,0-1.4.362l-2.432-2.432a3.489,3.489,0,0,0,0-3.537l2.917-2.917a2.962,2.962,0,0,0,1.4.362,3,3,0,1,0-3-3,2.967,2.967,0,0,0,.362,1.4L13.834,9A3.413,3.413,0,0,0,10.3,9l-1.76-1.76a2.956,2.956,0,0,0,.362-1.4,3,3,0,1,0-3,3,2.963,2.963,0,0,0,1.4-.362l1.759,1.76a3.486,3.486,0,0,0,0,3.537L7.932,14.9a2.963,2.963,0,0,0-1.4-.363,3,3,0,1,0,3,3,2.962,2.962,0,0,0-.362-1.4L10.3,15.013a3.413,3.413,0,0,0,3.537,0l2.431,2.432a2.99,2.99,0,1,0,2.638-1.6Zm.486-12.162a1,1,0,1,1-1,1A1,1,0,0,1,19.389,3.683ZM5.9,6.841a1,1,0,1,1,1-1A1,1,0,0,1,5.9,6.841Zm.632,11.7a1,1,0,1,1,1-1A1,1,0,0,1,6.531,18.541Zm5.535-5.034a1.5,1.5,0,1,1,1.06-.439A1.491,1.491,0,0,1,12.066,13.507ZM18.9,19.845a1,1,0,1,1,1-1A1,1,0,0,1,18.9,19.845Z" />
			</svg>
		</Icon>
	)
}
