import { useEffect, useState } from "react"

export enum ScriptStatus {
	LOADING,
	READY,
	ERROR,
}

const cachedScripts = new Map()

function loadScript(source: string, { async }: { async: boolean }): Promise<void> {
	let promise: Promise<void> = cachedScripts.get(source)
	if (!promise) {
		promise = new Promise<void>((resolve, reject) => {
			const script = document.createElement("script")
			script.src = source
			if (async) script.async = async
			script.onload = () => resolve()
			script.onerror = () => reject()
			document.body.appendChild(script)
		})
		cachedScripts.set(source, promise)
	}

	return promise
}

export function useScript(source: string, { async = false }: { async?: boolean } = {}): ScriptStatus {
	const [status, setState] = useState(ScriptStatus.LOADING)

	useEffect(() => {
		loadScript(source, { async })
			.then(() => setState(ScriptStatus.READY))
			.catch(() => setState(ScriptStatus.ERROR))
	}, [source, async])

	return status
}
