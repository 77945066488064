import { Route, Routes } from "react-router-dom"
import { LocalesList } from "./locales-list"

export const LocalesRoutes = () => {
	return (
		<Routes>
			<Route index element={<LocalesList />} />
		</Routes>
	)
}

export default LocalesRoutes
