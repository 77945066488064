import { useGetFestivalAwardsByRefQuery } from "@graphql/apollo"

import { getGlobalEntityTitleAttrs } from "../../../utils/global-titles"

export const useFestivalAwardEntity = (festivalAwardRef: string) => {
	if (!festivalAwardRef) throw new Error("useFestivalAwardEntity: mising ref parameter")

	const { data, loading } = useGetFestivalAwardsByRefQuery({
		fetchPolicy: "cache-and-network",
		variables: {
			ref: festivalAwardRef,
		},
	})

	const entity = data?.festivalAwardByRef
	const isCorrectEntity = entity?.__typename === "FestivalAward"

	const titleAttrs = isCorrectEntity ? getGlobalEntityTitleAttrs(entity) : undefined
	return {
		loading,
		festivalAward: isCorrectEntity ? entity : undefined,
		titleAttrs,
	}
}
