import { useEffect, useMemo, useState } from "react"
import { isDefined } from "./types"

export const clearStorage = () => localStorage.clear()

export const removeFromStorage = (key: string) => localStorage.removeItem(key)

export const readFromStorage = <T>(key: string) => {
	const value = localStorage.getItem(key)
	if (isDefined(value)) return JSON.parse(value) as T
	return null
}

export const writeToStorage = <T>(key: string, value: T) => {
	if (isDefined(value)) localStorage.setItem(key, JSON.stringify(value))
	else localStorage.removeItem(key)
}

export const useStorage = <T>(key: string, initialValues: T) => {
	const [value, setValue] = useState<T>(() => readFromStorage(key) || initialValues)
	const handleStorage = useMemo(
		() => (event: StorageEvent) => {
			if (event.key === key) setValue(readFromStorage(key) || initialValues)
		},
		[initialValues, key],
	)
	useEffect(() => {
		window.addEventListener("storage", handleStorage)
		return () => {
			window.removeEventListener("storage", handleStorage)
		}
	}, [handleStorage])

	return value
}
