import { nonempty, refine, string } from "superstruct"

const isValidURL = (value: string) => {
	try {
		new URL(value)
	} catch (_e) {
		return false
	}
	return true
}

export const Url = refine(nonempty(string()), "Url", (value) => {
	return isValidURL(value)
})
