import { StatusLight } from "@components/status-light"
import { Badge } from "../../../../components/badge"
import type { BrandOptionFragment, SeriesSeasonSearchEntityFragment } from "../../../../graphql/types"
import { ScreenIcon } from "../../../../icons/screen-icon"

interface SeriesListItemProps {
	season: SeriesSeasonSearchEntityFragment
	brand: BrandOptionFragment | undefined
}

const SeriesSeasonListItem = ({ season, brand }: SeriesListItemProps) => {
	const startYear = season?.originalPremiere?.match(/^[\d]{4}/)?.[0]
	const poster = season.brandData?.poster ?? season.poster
	const isActiveOnBrand = season.brandData?.poster?.ref

	return (
		<div className="flex gap-3">
			<span className="basis-16 shrink-0">
				<img
					alt="Poster"
					className="rounded aspect-poster object-cover w-16"
					src={poster ? `${__ENV__.IMAGE_CDN_URL}/c_74_100/${poster.path}` : "/images/default-image.png"}
				/>
			</span>
			<div className="flex flex-col gap-1">
				<div className="font-semibold seriess-baseline flex gap-2 text-wrap">
					<span>
						<ScreenIcon className="shrink-0" size="S" />
					</span>
					{brand !== undefined && (
						<span>
							<StatusLight variant={isActiveOnBrand ? "positive" : "negative"} />
						</span>
					)}
					<span>
						Season {season.number} <span>({season.series.title})</span>{" "}
					</span>
				</div>

				{startYear ? (
					<div className="flex gap-1 text-xs gray-500">
						<span className="font-semibold">{startYear}</span>
					</div>
				) : null}

				{poster && (
					<div className="flex gap-1 text-xs gray-500">
						<Badge>{poster.path}</Badge>
						<span className="truncate">{season.brandData?.poster?.ref}</span>
					</div>
				)}
			</div>
		</div>
	)
}

export default SeriesSeasonListItem
