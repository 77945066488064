import { useHotkey } from "./use-hotkey"

import { useCallback, useEffect, useState } from "react"
import { isEmpty } from "../utils/check"

export const DEBOUNCE_ULTRA_SHORT_DELAY = 10
export const DEBOUNCE_SHORT_DELAY = 500
export const DEBOUNCE_NORMAL_DELAY = 1000
export const DEBOUNCE_LONG_DELAY = 2000
export const DEBOUNCE_XL_LONG_DELAY = 5000

/**
 * Hook to debounce callbacks, mainly searches, but immediate if pressed on Enter key
 */
export const useDebounce = <T>(value: T, pDelay: number = DEBOUNCE_NORMAL_DELAY) => {
	const [pending, setPending] = useState(false)
	const [debouncedValue, setDebouncedValue] = useState<T>(value)
	const [timerId, setTimerId] = useState<NodeJS.Timeout>()

	useEffect(() => {
		setPending(true)
		const timer = setTimeout(() => {
			setDebouncedValue(value)
			setPending(false)
		}, pDelay)
		setTimerId(timer)
		return () => {
			clearTimeout(timer)
			setTimerId(undefined)
			setPending(false)
		}
	}, [value, pDelay])

	// immediately return value if enter is pressed
	useHotkey(
		"Enter,Escape",
		useCallback(
			(_event, hotkey) => {
				clearTimeout(timerId)
				if (hotkey === "Enter") setDebouncedValue(value)
				setPending(false)
			},
			[timerId, value],
		),
	)
	// immediately return value is no value is entered
	useEffect(() => {
		if (isEmpty(value)) {
			clearTimeout(timerId)
			setDebouncedValue(value)
			setPending(false)
		}
	}, [value, timerId])

	return [debouncedValue, { pending }] as const
}

/**
 * Hook to debounce callbacks, mainly searches, but immediate if pressed on Enter key
 */
export const useRevampedDebounce = <T>(value: T, pDelay: number = DEBOUNCE_NORMAL_DELAY) => {
	const [debouncedValue] = useDebounce(value, pDelay)
	return debouncedValue
}
