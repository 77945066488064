import { useRef, useState } from "react"
import { NavLink } from "react-router-dom"

import { Badge } from "@components/badge"
import { Link } from "@components/typography"
import { useHeaderInfoQuery } from "@graphql/apollo"
import { useOnClickOutside } from "@hooks/use-on-click-outside"
import { LogoutIcon } from "@icons/logout-icon"
import { MenuIcon } from "@icons/menu-icon"
import { UserIcon } from "@icons/user-icon"
import { useImpersonate, useSession, useSignOut } from "@utils/auth"

import { BugIcon } from "../../icons/bug-icon"
import { HelpIcon } from "../../icons/help-icon"
import GlobalSearchInput from "../shared/components/global-search-input"
import { Breadcrumbs } from "./breadcrumbs"

interface HeaderProps {
	onMenuToggle: () => void
}

export function Header({ onMenuToggle }: HeaderProps) {
	const { data } = useHeaderInfoQuery()
	const [showUserPanel, setShowUserPanel] = useState(false)
	const session = useSession()
	const impersonate = useImpersonate()
	const signOut = useSignOut()
	const userPanelRef = useRef<HTMLUListElement>(null)

	useOnClickOutside(userPanelRef, () => {
		setShowUserPanel(false)
	})

	const user = data?.viewer
	const isImpersonating = session?.originalToken != null

	return (
		<div className="bg-white fixed z-20 top-0 w-full">
			<header className="relative h-14 flex justify-between items-center border-b border-gray-200">
				<div className="flex items-center h-full w-full">
					<div className="flex shrink-0 items-center h-full border-r border-gray-200" style={{ width: "calc(15rem - 1px)" }}>
						<span className="p-4 cursor-pointer" onClick={() => onMenuToggle()}>
							<MenuIcon />
						</span>
						<Link to="/">
							<img alt="Boxoffice Logo" src="/images/boxoffice-logo.svg" className="w-[160px]" />
						</Link>
					</div>
					<Breadcrumbs />
					<div className="ml-auto h-full border-r border-l border-gray-200">
						<GlobalSearchInput />
					</div>
					<div className="h-full border-r border-gray-200 flex flex-col gap-1 pl-4 pr-4 text-sm justify-center overflow-hidden relative">
						<a
							href="mailto:support-cms@boxoffice.com?subject=Question about BOCMS"
							target="_blank"
							rel="noreferrer"
							title="Ask for support by email"
							className="hover:underline text-blue-700 text-ellipsis truncate min-w-8"
						>
							<HelpIcon className="mr-1 " />A question ?
						</a>
						<a
							href="mailto:support-cms@boxoffice.com?subject=Error on BOCMS"
							target="_blank"
							rel="noreferrer"
							title="Report an issue by email"
							className="hover:underline text-amber-700 text-ellipsis truncate min-w-8"
						>
							<BugIcon className="mr-1 " />
							An issue ?
						</a>
					</div>
					{user ? (
						<div
							onClick={() => setShowUserPanel((value) => !value)}
							className="cursor-pointer h-full flex flex-col justify-center pl-4 pr-4"
						>
							<div className="flex gap-2 items-center text-center whitespace-nowrap">
								{isImpersonating ? <Badge color="warning">Impersonating</Badge> : null}
								{user.name}
							</div>
							{showUserPanel ? (
								<ul
									className="absolute text-sm z-20 bg-white top-full right-4 w-60 whitespace-nowrap shadow"
									ref={userPanelRef}
								>
									<li className="hover:bg-gray-100">
										<NavLink
											className="flex gap-4 items-center cursor-pointer p-4"
											to={user.author ? `/authors/${user.author.ref}` : "/authors/create?self"}
										>
											<UserIcon />
											Edit author profile
										</NavLink>
									</li>
									{isImpersonating ? (
										<li className="hover:bg-gray-100">
											<span className="flex gap-4 items-center cursor-pointer p-4" onClick={() => impersonate(null)}>
												<LogoutIcon />
												Exit impersonation
											</span>
										</li>
									) : (
										<li className="hover:bg-gray-100">
											<span className="flex gap-4 items-center cursor-pointer p-4" onClick={() => signOut()}>
												<LogoutIcon />
												Sign out
											</span>
										</li>
									)}
								</ul>
							) : null}
						</div>
					) : null}
				</div>
			</header>
		</div>
	)
}
