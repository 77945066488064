import type { IconPropsWithoutChildren } from "@components/icon"
import { Icon } from "@components/icon"

export function KeyboardArrowLeftIcon(props: IconPropsWithoutChildren) {
	return (
		<Icon {...props}>
			<svg viewBox="0 0 24 24">
				<path d="M14 18 8 12 14 6 15.4 7.4 10.8 12 15.4 16.6Z" />
			</svg>
		</Icon>
	)
}
