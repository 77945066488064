import { useMemo } from "react"

export type CopyableValueProps = {
	value: string | number
	escapeSpecialChars?: boolean
	escapeFn?: (v: string | number) => string
}

export const useCopyableValue = ({
	value: pValue,
	escapeSpecialChars = false,
	escapeFn = (v: string | number) => `${v}`.replace(/^#\s*/g, ""),
}: CopyableValueProps) => {
	const value = useMemo(
		() => (escapeSpecialChars ? escapeFn(pValue) : `${pValue}`),
		[escapeFn, pValue, escapeSpecialChars],
	)
	return value
}
