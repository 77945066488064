export type Unpack<A> = A extends Array<infer E> ? E : A

/**
 * Remove {} from T
 */
export type NoEmpty<T> = T extends object ? (keyof T extends never ? never : T) : T

/**
 * Distribute a Omit over a interface union (works with single interface too)
 */

// biome-ignore lint/suspicious/noExplicitAny: too variadic
export type DistributiveOmit<T, K extends keyof any> = T extends any ? Omit<T, K> : never

/**
 * Distribute a Pick over a interface union (works with single interface too)
 */
// biome-ignore lint/suspicious/noExplicitAny: too variadic
export type DistributivePick<T, K extends keyof T> = T extends any ? Pick<T, K> : never

export const isDefined = <T>(v: T): v is NonNullable<T> => v !== undefined && v !== null

export const isNotEmpty = <T>(v: T): v is NonNullable<T> => v !== undefined && v !== null && v !== ""

export const isDefinedOrNull = <T>(v: T | null | undefined): v is T | null => v !== undefined || v === null
