import clsx from "clsx"
import type { ReactNode } from "react"
import { Link as RouterLink } from "react-router-dom"

import { ClearIcon } from "@icons/clear-icon"

interface AProps {
	children: ReactNode
	href: string
	className?: string
	target?: string
}

export function A(props: AProps) {
	const { className, children, href, target } = props
	return (
		<a className={clsx(className, "text-black hover:text-gray-500 hover:underline")} href={href} target={target}>
			{children}
		</a>
	)
}

interface LinkProps {
	children: ReactNode
	to: string
	className?: string
}

export function Link(props: LinkProps) {
	const { className, children, to } = props
	return (
		<RouterLink className={clsx(className, "text-black hover:text-gray-500 hover:underline")} to={to}>
			{children}
		</RouterLink>
	)
}

interface ErrorTextProps {
	children: ReactNode
	className?: string
}

export function ErrorText(props: ErrorTextProps) {
	const { className, children } = props
	return (
		<div className={clsx(className, "text-red-700 text-2xl font-bold flex items-center gap-2")}>
			<ClearIcon size="L" />
			{children}
		</div>
	)
}

interface HeadingProps {
	children: ReactNode
	className?: string
}

export function Heading(props: HeadingProps) {
	const { className, children } = props
	return <h2 className={clsx(className, "border-b border-solid border-gray-200 text-lg p-2.5")}>{children}</h2>
}
