import type { Params } from "react-router-dom"

import { BreadCrumbLabel } from "../../components/breadcrumbs"
import { useFestivalEditionEntity } from "./hooks/festival-edition"

export const FestivalEditionBreadCrumbTitle = ({
	match,
}: {
	match: Params<string>
}) => {
	if (!(match.festival && match.edition)) throw new Error("FestivalEditionBreadCrumbTitle:requirements not met")

	const { titleAttrs } = useFestivalEditionEntity({
		festivalRef: match.festival,
		festivalEditionRef: match.edition,
	})
	return <BreadCrumbLabel label={titleAttrs?.label} copyable />
}

export default FestivalEditionBreadCrumbTitle
