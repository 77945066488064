export const FESTIVAL_OUT_OF_COMPETITION_ID = "out-of-section"

const selectionOutOfCompetitonName = "Not in a section"
export const FESTIVAL_OUT_OF_COMPETITION_VIRTUAL_FG = {
	__typename: "FestivalCompetition",
	id: FESTIVAL_OUT_OF_COMPETITION_ID,
	ref: FESTIVAL_OUT_OF_COMPETITION_ID,
	name: selectionOutOfCompetitonName,
	localizedData: {
		name: selectionOutOfCompetitonName,
	},
}
