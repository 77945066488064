import clsx from "clsx"
import type { HTMLAttributes } from "react"

type BackdropProps = HTMLAttributes<HTMLDivElement> & {
	isClickable?: boolean
}

export function Backdrop(props: BackdropProps) {
	const { children, className, isClickable = true, ...rest } = props
	return (
		<div
			className={clsx(className, "fixed inset-0 z-50 drop-shadow-xl bg-black/25 shadow-lg overflow-hidden outline-0", {
				"cursor-pointer": isClickable,
			})}
			{...(isClickable ? { onClick: (e) => e.stopPropagation() } : {})}
			{...rest}
		>
			{children}
		</div>
	)
}
