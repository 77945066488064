import { useHotkey } from "@hooks/use-hotkey"
import clsx from "clsx"
import type { HTMLAttributes } from "react"
import { useState } from "react"
import { ClearIcon } from "../icons/clear-icon"
import { Backdrop } from "./backdrop"
import { Button } from "./button"
import { ErrorBoundary } from "./error-boundary"
import { ErrorMessage } from "./error-message"
import { Portal } from "./portal"

interface ModalProps extends HTMLAttributes<HTMLDivElement> {
	isClosable?: boolean
	padding?: boolean
	onClose?: () => void
	title?: string
}

export function Modal({
	children,
	className,
	onClose,
	padding = true,
	isClosable: pIsClosable = true,
	title,
	...rest
}: ModalProps) {
	useHotkey("Escape", () => {
		onClose?.()
	})

	const [isClosable, setClosable] = useState(pIsClosable)

	return (
		<Portal>
			<Backdrop
				isClickable={isClosable}
				{...(isClosable
					? {
							onClick: () => {
								if (onClose) onClose()
							},
							onMouseDown: () => {
								setClosable(true)
							},
						}
					: {})}
			>
				<div
					className={clsx(
						className,
						"w-fit m-auto mt-4 overflow-auto bg-white rounded-md cursor-default ",
						"max-w-[calc(100vw-4rem)] max-h-[calc(100vh-2rem)]",
						padding ? "p-4" : "p-0",
					)}
					onClick={(event) => event.stopPropagation()}
					onMouseDown={() => {
						setClosable(false)
					}}
					{...rest}
				>
					<div
						className={clsx("flex mb-2", {
							"justify-between": Boolean(title),
							"justify-end": Boolean(!title),
						})}
					>
						{title ? <div className="font-semibold">{title}</div> : null}
						<div className="cursor-pointer" onClick={onClose}>
							<ClearIcon size="M" />
						</div>
					</div>
					<ErrorBoundary
						fallbackRender={({ error }) => (
							<div className="flex flex-col gap-2">
								<ErrorMessage error={error} />
								<div className="flex gap-4 justify-end">
									<Button onClick={onClose}>Close</Button>
								</div>
							</div>
						)}
					>
						<div className="max-h-[calc(100vh-2rem)] min-w-[45rem] min-h-[5rem] overflow-auto">{children}</div>
					</ErrorBoundary>
				</div>
			</Backdrop>
		</Portal>
	)
}
