import clsx from "clsx"
import type { ReactNode } from "react"

type FormFieldLabelProps = { children: ReactNode; labelIcon?: ReactNode; className?: string }
const FormFieldLabel = ({ children, labelIcon, className }: FormFieldLabelProps) =>
	labelIcon ? (
		<div className={clsx("flex flex-row gap-1", className)}>
			{children}
			{labelIcon}
		</div>
	) : (
		children
	)

export default FormFieldLabel
