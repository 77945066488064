import clsx from "clsx"
import { FormattedNumber } from "react-intl"

import { KeyboardArrowLeftIcon } from "@icons/keyboard-arrow-left-icon"
import { KeyboardArrowRightIcon } from "@icons/keyboard-arrow-right-icon"

import { IconButton } from "./button"
import type { SelectOption } from "./select"
import { Select, createOption } from "./select"

interface TablePaginationProps {
	className?: string
	count: number
	page: number
	rowsPerPage: number
	rowsPerPageOptions?: SelectOption[]
	onChangePage(value: number): void
	onChangeRowsPerPage(value: number): void
}

type PaginationOption = SelectOption<number>

const DEFAULT_ROWS_PER_PAGE = [createOption(10), createOption(20), createOption(30), createOption(50)]

export function TablePagination(props: TablePaginationProps) {
	const { className, count, page, rowsPerPage, rowsPerPageOptions, onChangePage, onChangeRowsPerPage } = props

	const from = count === 0 ? 0 : page * rowsPerPage + 1
	const to = Math.min(count, (page + 1) * rowsPerPage)

	const handlePreviousPage = () => onChangePage(page - 1)
	const handleNextPage = () => onChangePage(page + 1)

	return (
		<div className={clsx(className, "flex items-center justify-end text-sm text-gray-600 gap-x-8")}>
			<div className="flex gap-x-2 items-center">
				<span>Rows per page</span>
				<Select<PaginationOption>
					className="mx-1"
					value={createOption(rowsPerPage)}
					options={rowsPerPageOptions ?? DEFAULT_ROWS_PER_PAGE}
					onChange={(option) => {
						if (option) onChangeRowsPerPage(option.value)
					}}
				/>
			</div>
			<div>
				<FormattedNumber value={from} />
				{"–"}
				<FormattedNumber value={to} />
				{" of "}
				<FormattedNumber value={count} />
			</div>
			<IconButton title="Previous" disabled={page === 0} onClick={handlePreviousPage} color="secondary">
				<KeyboardArrowLeftIcon />
			</IconButton>
			<IconButton
				title="Next"
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				onClick={handleNextPage}
				color="secondary"
			>
				<KeyboardArrowRightIcon />
			</IconButton>
		</div>
	)
}
