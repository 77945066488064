import { type ReactNode, type ReactPortal, useLayoutEffect, useMemo } from "react"
import { createPortal } from "react-dom"

type PortalProps = {
	children: ReactNode
	type?: string
	className?: string
}

export function Portal({ children, type = "div", className }: PortalProps): ReactPortal {
	const host = useMemo(() => {
		const el = document.createElement(type)
		if (className) el.className = className
		return el
	}, [type, className])

	useLayoutEffect(() => {
		document.body.appendChild(host)
		return () => {
			document.body.removeChild(host)
		}
	}, [host])

	return createPortal(children, host)
}
