import { useEffect, useState } from "react"
type RankDragAndDropRecord<T> = [
	list: T[],
	handleHover: (sourceId: string, targetId: string) => void,
	handleDrop: (id: string) => void,
]
type RankDragAndDropConfig<T> = {
	getId?: (item: T) => string
	getRank?: (item: T) => number
	forceRankUpdate?: boolean
	isReverse?: boolean // allow to  support cases where ranking is inverted (ex: from last to first images of an entity, last as first item)
}

const defaultGetId = <T extends { id?: string }>(item: T) => item.id
const defaultGetRank = <T extends { rank?: number | null }>(item: T) => item.rank

/**
 * Provide listeners to use with react-dnd when we need to display a
 * drag-n-dropable list of ranked items (tags, authors, ...)
 *
 * Provided items must have a "rank" field (or specify another field as third argument)
 */
export function useRankDragAndDrop<
	T extends Partial<{ id: string; rank: number | null }> & Record<string | number | symbol, unknown>,
>(initialList: T[], onDrop: (i: T, rank: number) => void, config: RankDragAndDropConfig<T> = {}) {
	const [list, setList] = useState(initialList)
	const [rankingIntent, setRankingIntent] = useState<"forth" | "back" | "untouched">("untouched")
	const { getId = defaultGetId, getRank = defaultGetRank, forceRankUpdate = false, isReverse = false } = config
	const firstRank = (initialList.length > 0 ? getRank(initialList[0]) : undefined) ?? 1
	// Make sure to keep list in sync (for instance if a graph query updates it)
	useEffect(() => {
		setList(initialList)
	}, [initialList])
	const handleHover = (sourceId: string, targetId: string) => {
		const sourceIndex = list.findIndex((item) => getId(item) === sourceId)
		const targetIndex = list.findIndex((item) => getId(item) === targetId)

		if (targetIndex === -1 || sourceIndex === -1) return

		let newList = []
		let newRankingIntent: "forth" | "back" | "untouched" = "untouched"
		if (sourceIndex < targetIndex) {
			newRankingIntent = "forth"
			newList = [
				...list.slice(0, sourceIndex),
				...list.slice(sourceIndex + 1, targetIndex + 1),
				list[sourceIndex],
				...list.slice(targetIndex + 1),
			] // Move the item below the target item
		} else if (sourceIndex > targetIndex) {
			newRankingIntent = "back"
			newList = [
				...list.slice(0, targetIndex),
				list[sourceIndex],
				...list.slice(targetIndex, sourceIndex),
				...list.slice(sourceIndex + 1),
			] // Move the item above the target item
		} else newList = list // do not change anything

		setRankingIntent(newRankingIntent)
		setList(newList)
	}
	const handleDrop = (id: string) => {
		const index = list.findIndex((item) => getId(item) === id)
		if (index === -1) return

		const item = list[index]
		const itemRank = getRank(item) ?? 1
		const targetIndex = index + 1
		let newRank: number = targetIndex
		if (isReverse) {
			if (rankingIntent === "untouched") newRank = itemRank
			else if (rankingIntent === "forth") newRank = firstRank - index
			else newRank = Math.min(firstRank - index, firstRank)
		} else newRank = Math.max(targetIndex, 1)
		if (forceRankUpdate) onDrop(item, newRank)
		else if (newRank !== itemRank) onDrop(item, newRank)
	}
	return [list, handleHover, handleDrop] as RankDragAndDropRecord<T>
}
