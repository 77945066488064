import { type ComponentType, useMemo } from "react"
import { Field, Form } from "react-final-form"
import { Button } from "../../../components/button"
import { FieldError, FormInput } from "../../../components/form"
import { FormField } from "../../../components/form-field"
import type { IconPropsWithoutChildren } from "../../../components/icon"
import { createOption } from "../../../components/select"
import { type WidgetIdentityFragment, WidgetType } from "../../../graphql/types"
import { BlockIcon } from "../../../icons/block-icon"
import { SaveIcon } from "../../../icons/save-icon"
import { isNotEmpty, isURL } from "../../../utils/validators"
import { SelectWidgetType, type WidgetTypeOption } from "../../shared/components/select-widget-type"

type WidgetIdentityFormProps = {
	onCancel?: () => void
	onSubmit: (values: WidgetIdentityFormValues) => void
	widget?: WidgetIdentityFragment
	submitButtonLabel?: string
	submitButtonIcon?: ComponentType<IconPropsWithoutChildren>
}

export type WidgetIdentityFormValues = {
	name: string
	type: WidgetTypeOption
	url: string | null
}

export const WidgetIdentityForm = ({
	onCancel,
	onSubmit,
	widget,
	submitButtonLabel = "Save",
	submitButtonIcon = SaveIcon,
}: WidgetIdentityFormProps) => {
	const initialValues = useMemo(
		() =>
			widget
				? {
						name: widget.name,
						url: widget.url,
						type: createOption(widget.type),
					}
				: undefined,
		[widget],
	)

	return (
		<Form<WidgetIdentityFormValues>
			initialValues={initialValues}
			onSubmit={onSubmit}
			subscription={{
				submitting: true,
				values: true,
			}}
		>
			{({ handleSubmit, submitting, values }) => (
				<form onSubmit={handleSubmit} className="flex flex-col gap-2 p-2">
					<FormField label="Name" isRequired>
						<Field name="name" component={FormInput} validate={isNotEmpty} />
						<FieldError name="name" />
					</FormField>
					<FormField label="Url" isRequired={values.type?.value === WidgetType.Metabase}>
						<Field
							name="url"
							component={FormInput}
							validate={(value) =>
								values.type?.value === WidgetType.Metabase ? isNotEmpty(value) || isURL(value) : isURL(value)
							}
						/>
						<FieldError name="url" />
					</FormField>
					<FormField label="Type" isRequired>
						<Field
							validate={isNotEmpty}
							name="type"
							render={({ input, meta }) => <SelectWidgetType {...input} invalid={meta.touched && meta.invalid} />}
						/>
						<FieldError name="type" />
					</FormField>
					<div className="flex gap-4 justify-end mt-2">
						{onCancel !== undefined && (
							<Button color="secondary" icon={BlockIcon} onClick={onCancel}>
								Cancel
							</Button>
						)}
						<Button type="submit" icon={submitButtonIcon} loading={submitting}>
							{submitButtonLabel}
						</Button>
					</div>
				</form>
			)}
		</Form>
	)
}
