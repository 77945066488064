import clsx from "clsx"
import { createContext, useEffect, useState } from "react"
import { Outlet } from "react-router-dom"

import { Unavailable } from "@components/error"
import { ErrorBoundary } from "@components/error-boundary"

import { useMinXL } from "../../hooks/use-media-query"
import { Header } from "./header"
import { MainMenu } from "./main-menu"

export const MenuContext = createContext({
	showMenu: true,
})

export function Shell() {
	const isVisible = useMinXL()
	const [showMenu, setShowMenu] = useState(isVisible)

	useEffect(() => {
		setShowMenu(isVisible)
	}, [isVisible])

	return (
		<div className="relative">
			<Header onMenuToggle={() => setShowMenu((value) => !value)} />
			<MainMenu showMenu={showMenu} />
			<MenuContext.Provider value={{ showMenu }}>
				<main
					style={{
						// 3.5rem is header height, we
						// chose to not make a variable
						minHeight: "calc(100vh - 3.5rem)",
					}}
					className={clsx("p-4 mt-14 bg-white flex flex-col gap-4", {
						"ml-60": showMenu,
					})}
				>
					<ErrorBoundary fallbackComponent={Unavailable}>
						<Outlet />
					</ErrorBoundary>
				</main>
			</MenuContext.Provider>
		</div>
	)
}
