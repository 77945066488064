import { useParams } from "react-router-dom"
import { BoundaryCard, Card } from "../../components/card"
import { useNotifications } from "../../components/notification"
import { useGetWidgetQuery, useUpdateWidgetMutation } from "../../graphql/apollo"
import { useDocumentTitle } from "../../hooks/use-document-title"
import { expect } from "../../utils/assert"
import { WidgetIdentityForm } from "./components/widget-identity-form"

const Component = () => {
	const { notify } = useNotifications()
	const params = useParams()
	const widgetRef = expect(params.id)
	useDocumentTitle(`Widget #${widgetRef}`)

	const { data } = useGetWidgetQuery({
		variables: {
			ref: widgetRef,
		},
	})

	const [updateWidget] = useUpdateWidgetMutation({
		onCompleted: () => {
			notify("success", "Widget updated successfully")
		},
		onError: () => {
			notify("error", "Widget could not be updated")
		},
	})

	const widget = data?.entityByRef?.__typename === "Widget" ? data?.entityByRef : undefined

	return (
		<Card header="Edit widget">
			<WidgetIdentityForm
				widget={widget}
				onSubmit={(values) => {
					if (widget) {
						return updateWidget({
							variables: {
								input: {
									widgetId: widget.id,
									name: values.name,
									type: values.type.value,
									url: values.url,
								},
							},
						})
					}
					return null
				}}
			/>
		</Card>
	)
}

export const WidgetEdit = () => {
	return (
		<BoundaryCard>
			<Component />
		</BoundaryCard>
	)
}
