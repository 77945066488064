import {
	useQuery as ApolloUseQuery,
	type DocumentNode,
	type NoInfer,
	type QueryHookOptions,
	type QueryResult,
	type SkipToken,
	type SuspenseQueryHookOptions,
} from "@apollo/client"
import type { OperationVariables } from "@apollo/client"

declare module "@apollo/client" {
	interface SuspenseQueryHookOptions {
		throw?: boolean
	}
	interface QueryHookOptions {
		throw?: boolean
	}
}

// biome-ignore lint/suspicious/noExplicitAny: difficult to type here
export function useQuery<TData = any, TVariables extends OperationVariables = OperationVariables>(
	query: DocumentNode,
	options?: (
		| SkipToken
		| SuspenseQueryHookOptions<NoInfer<TData>, NoInfer<TVariables>>
		| QueryHookOptions<NoInfer<TData>, NoInfer<TVariables>>
	) & {
		throw?: boolean
	},
): QueryResult<TData, TVariables> {
	const _throw = options?.throw ?? true

	const result = ApolloUseQuery(query, options)

	if (_throw && result.error) throw result.error

	return result
}

export type {
	LazyQueryHookOptions,
	MutationHookOptions,
	SuspenseQueryHookOptions,
	QueryHookOptions,
	SkipToken,
} from "@apollo/client"

export { useLazyQuery, useMutation, useSuspenseQuery, skipToken } from "@apollo/client"
