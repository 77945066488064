import { useLayoutEffect, useRef } from "react"

/**
 * Hook to define an event handler with an always-stable function identity.
 *
 * It's a polyfill for the future built-in `useEvent` from React.
 * See https://github.com/reactjs/rfcs/blob/useevent/text/0000-useevent.md#internal-implementation
 */

// biome-ignore lint/suspicious/noExplicitAny: can be anything here
export function useEvent<T extends (...args: any[]) => any>(callback: T): T {
	// biome-ignore lint/suspicious/noExplicitAny: can be anything here
	const latestRef = useRef<any>(null)
	const stableRef = useRef<T>()

	useLayoutEffect(() => {
		latestRef.current = callback
	}, [callback])

	if (!stableRef.current) {
		// biome-ignore lint/suspicious/noExplicitAny: can be anything here
		stableRef.current = function (this: any) {
			// biome-ignore lint/correctness/noUndeclaredVariables: Variadic arguments
			// biome-ignore lint/style/noArguments: Variadic arguments
			return latestRef.current.apply(this, arguments)
		} as T
	}

	return stableRef.current
}
