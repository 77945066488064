import { SelectCountry } from "pages/shared/components/select-country"
import { type LocalizationOption, SelectLocalization } from "pages/shared/components/select-localization"
import { type ComponentType, useMemo } from "react"
import { Field, Form } from "react-final-form"
import { Button } from "../../../components/button"
import { FieldError, FormInput } from "../../../components/form"
import { FormField } from "../../../components/form-field"
import type { IconPropsWithoutChildren } from "../../../components/icon"
import type { CertificateIdentityFragment, CountryOptionFragment } from "../../../graphql/types"
import { BlockIcon } from "../../../icons/block-icon"
import { SaveIcon } from "../../../icons/save-icon"
import { isNotEmpty } from "../../../utils/validators"

type CertificateIdentityFormProps = {
	onCancel?: () => void
	onSubmit: (values: CertificateIdentityFormValues) => void
	certificate?: CertificateIdentityFragment
	submitButtonLabel?: string
	submitButtonIcon?: ComponentType<IconPropsWithoutChildren>
	disableCountrySelection?: boolean
}

export type CertificateIdentityFormValues = {
	certificateId: string
	label: string | null
	country: CountryOptionFragment
	localization: LocalizationOption | null
	code: string | null
}

export const CertificateIdentityForm = ({
	onCancel,
	onSubmit,
	certificate,
	submitButtonLabel = "Save",
	submitButtonIcon = SaveIcon,
	disableCountrySelection = false,
}: CertificateIdentityFormProps) => {
	const initialValues = useMemo(
		() =>
			certificate
				? {
						certificateId: certificate?.id,
						label: certificate?.label,
						code: certificate?.code,
						country: certificate.country,
						localization: certificate?.limitedLocalization,
					}
				: undefined,
		[certificate],
	)
	return (
		<Form<CertificateIdentityFormValues>
			initialValues={initialValues}
			onSubmit={onSubmit}
			subscription={{
				submitting: true,
				values: true,
			}}
		>
			{({ form, handleSubmit, submitting, values }) => (
				<form onSubmit={handleSubmit} className="flex flex-col gap-2 p-2">
					<FormField label="Code" isRequired>
						<Field name="code" component={FormInput} validate={isNotEmpty} />
						<FieldError name="code" />
					</FormField>
					<FormField label="Label">
						<Field name="label" component={FormInput} />
						<FieldError name="label" />
					</FormField>
					<FormField label="Country" isRequired>
						<Field
							name="country"
							validate={isNotEmpty}
							render={({ input, meta }) => (
								<SelectCountry
									isDisabled={disableCountrySelection}
									{...input}
									invalid={meta.touched && meta.invalid}
									onChange={(newValue) => {
										form.change("localization", null)
										input.onChange(newValue ?? undefined)
									}}
								/>
							)}
						/>
						<FieldError name="country" />
					</FormField>
					<FormField label="Localization">
						<Field
							name="localization"
							render={({ input, meta }) => (
								<SelectLocalization
									{...input}
									isDisabled={!values?.country?.id}
									countryId={values?.country?.id}
									invalid={meta.touched && meta.invalid}
									onChange={(newValue) => {
										form.change("localization", newValue ?? undefined)
									}}
								/>
							)}
						/>
						<FieldError name="localization" />
					</FormField>
					<div className="flex gap-3 justify-end mt-2">
						{onCancel !== undefined && (
							<Button color="secondary" icon={BlockIcon} onClick={onCancel}>
								Cancel
							</Button>
						)}
						<Button type="submit" icon={submitButtonIcon} loading={submitting}>
							{submitButtonLabel}
						</Button>
					</div>
				</form>
			)}
		</Form>
	)
}
