import clsx from "clsx"
import type { ClipboardEventHandler, ForwardedRef, InputHTMLAttributes } from "react"
import { forwardRef, useLayoutEffect } from "react"

import { useForwardedRef } from "@hooks/use-forwarded-ref"

import type { FocusableProps } from "../types/events"
import type { InputBase, TextInputBase, ValueBase } from "../types/inputs"
import { inputStyle } from "./input"

export interface TextareaProps
	extends Pick<InputHTMLAttributes<HTMLTextAreaElement>, "onCopy">,
		InputBase,
		ValueBase<string>,
		TextInputBase,
		FocusableProps {
	isDynamic?: boolean
	isMonospaced?: boolean
	rows?: number
	onPaste?: ClipboardEventHandler<HTMLTextAreaElement>
	className?: string
	preventLineBreaks?: boolean
	disabled?: boolean
}

function Textarea(props: TextareaProps, ref: ForwardedRef<HTMLTextAreaElement>) {
	const inputRef = useForwardedRef(ref)
	const {
		disabled,
		isDisabled: pIsDisabled = false,
		isInvalid = false,
		isReadOnly = false,
		isDynamic = false,
		isMonospaced = false,
		preventLineBreaks = false,
		rows,
		onChange,
		onPaste,
		className,
		...otherProps
	} = props

	const isDisabled = disabled || pIsDisabled

	// biome-ignore lint/correctness/useExhaustiveDependencies: set on props.value change
	useLayoutEffect(() => {
		const input = inputRef.current
		if (input && isDynamic) {
			input.style.height = "auto"
			input.style.height = `${input.scrollHeight}px`
		}
	}, [inputRef, isDynamic, props.value])

	return (
		<textarea
			ref={inputRef}
			rows={isDynamic ? 1 : rows}
			className={clsx(
				inputStyle({
					readOnly: isReadOnly,
					disabled: isDisabled,
					invalid: isInvalid,
				}),
				"min-h-[28px]",
				{
					"overflow-hidden resize-none": isDynamic,
					"font-mono": isMonospaced,
				},
				className,
			)}
			{...otherProps}
			disabled={isDisabled}
			readOnly={isReadOnly}
			onPaste={onPaste}
			onChange={({ target: { value } }) => {
				if (!onChange) return
				onChange(preventLineBreaks ? value.replace(/\r?\n|\r/g, "") : value)
			}}
		/>
	)
}

const _Textarea = forwardRef(Textarea)
export { _Textarea as Textarea }
