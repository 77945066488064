export enum ModalTypes {
	EDIT_MODAL = "EDIT_MODAL",
	DELETE_MODAL = "DELETE_MODAL",
	CREATE_MODAL = "CREATE_MODAL",
}

type ModalReducerState<T> = {
	isOpened: boolean
	type: ModalTypes | null
	payload: T | null
}
type ModalActions<T> =
	| {
			type: ModalTypes.CREATE_MODAL
	  }
	| {
			type: ModalTypes.DELETE_MODAL | ModalTypes.EDIT_MODAL
			payload: T
	  }
	| {
			type: "reset"
	  }

export const modalReducer = <T,>(_state: ModalReducerState<T>, action: ModalActions<T>) => {
	switch (action.type) {
		case ModalTypes.CREATE_MODAL:
			return {
				payload: null,
				isOpened: true,
				type: action.type,
			}
		case ModalTypes.EDIT_MODAL:
		case ModalTypes.DELETE_MODAL:
			return {
				isOpened: true,
				type: action.type,
				payload: action.payload,
			}
		case "reset":
			return {
				type: null,
				isOpened: false,
				payload: null,
			}
	}
}
