import type { FetchLibrary, RequestDelayFunction, RequestRetryOnFunction } from "fetch-retry"
import fetchRetry from "fetch-retry"

const MAX_REQ_RETRIES = 30

const retryDelay: RequestDelayFunction<FetchLibrary> = (attempt) => 2 ** attempt * 1000

const retryOn: RequestRetryOnFunction<FetchLibrary> = (attempt, error, response) => {
	if (attempt >= MAX_REQ_RETRIES) return false
	if (error !== null || (response?.status ?? 0) >= 500) return true
	return false
}

const retriableFetch = fetchRetry(fetch, {
	retries: 30,
	retryOn,
	retryDelay,
})

export default retriableFetch
