import clsx from "clsx"

import type { IconPropsWithoutChildren } from "@components/icon"
import { Icon } from "@components/icon"
import type { ButtonColor } from "../components/button.types"

type LoaderIconProps = IconPropsWithoutChildren & {
	color?: ButtonColor
}

export function LoaderIcon(props: LoaderIconProps) {
	const { color = "danger", animate = true } = props
	return (
		<Icon disabledClasses={["fill-current"]} {...props}>
			<svg
				className={clsx({
					"animate-spin": animate,
					"text-red-600": color === "danger",
					"text-white-600": color !== "danger",
				})}
				fill="none"
				viewBox="0 0 24 24"
			>
				<circle cx="12" cy="12" r="10" stroke="currentColor" strokeOpacity="0.25" strokeWidth="4" />
				<path
					d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
					fill="currentColor"
					fillOpacity="0.75"
				/>
			</svg>
		</Icon>
	)
}
