import type { ReactNode } from "react"
import type { MinimumIdentifiableEntity } from "../types/entities"
import { getGlobalEntityLinkAttrs } from "../utils/global-routes"
import type { GlobalEntityPossibleTitleKeys } from "../utils/global-titles"

export const GlobalEntityTitle = <E extends MinimumIdentifiableEntity>({
	entity,
	subRoute,
	defaultLabel = "-",
	labelType = "label",
	children,
	className,
}: {
	entity: E
	subRoute?: string
	defaultLabel?: string
	labelType?: GlobalEntityPossibleTitleKeys
	className?: string
	children?: ReactNode
}) => {
	const titleAttrs = getGlobalEntityLinkAttrs<E>(entity, subRoute)
	const fLabel = titleAttrs[labelType] ?? titleAttrs.label ?? defaultLabel

	return (
		<span className={className}>
			{fLabel}
			{children}
		</span>
	)
}
