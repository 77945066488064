import clsx from "clsx"
import { createElement } from "react"
import type { FieldMetaState } from "react-final-form"
import { useField } from "react-final-form"

import type { CheckboxProps } from "./checkbox"
import { Checkbox } from "./checkbox"
import { DatePickerRevamped, type DatePickerRevampedProps } from "./date-picker-revamped"
import type { InputProps } from "./input"
import { Input } from "./input"
import { InputCurrency, type InputCurrencyProps } from "./input-currency"
import type { SelectProps } from "./select"
import { Select } from "./select"
import type { TagInputProps } from "./tag-input"
import { TagInput } from "./tag-input"
import type { TextareaProps } from "./textarea"
import { Textarea } from "./textarea"
import type { ToggleProps } from "./toggle"
import { Toggle } from "./toggle"

type FieldErrorProps = {
	name: string
	className?: string
	withFieldName?: boolean
}

type FieldComponentProps = {
	// biome-ignore lint/suspicious/noExplicitAny: too variadic
	input: any
	// biome-ignore lint/suspicious/noExplicitAny: too variadic
	meta: FieldMetaState<any>
}

export function FieldError({ name, className, withFieldName = false }: FieldErrorProps) {
	const { meta } = useField(name, {
		subscription: {
			error: true,
			submitError: true,
			dirty: true,
			touched: true,
		},
	})

	const error = meta.submitError || meta.error
	if ((meta.touched || meta.dirty) && error)
		return (
			<div className={clsx(className, "text-sm text-red-700")}>
				{withFieldName ? `${name}: ` : undefined}
				{error}
			</div>
		)
	return null
}

function extractProps({ input, meta, ...rest }: FieldComponentProps) {
	return {
		...input,
		...rest,
		invalid: meta.touched && meta.invalid,
	}
}

export function FormCheckbox(props: CheckboxProps & FieldComponentProps) {
	const { input, meta, ...otherProps } = props
	return <Checkbox isInvalid={meta.touched && meta.invalid} isSelected={input.checked} {...input} {...otherProps} />
}

export function FormToggle(props: ToggleProps & FieldComponentProps) {
	return createElement(Toggle, extractProps(props))
}

export function FormDatePickerRevamped<R extends Date | string = string>(
	props: DatePickerRevampedProps<R> & FieldComponentProps,
) {
	return createElement(DatePickerRevamped, extractProps(props))
}

export function FormInput(props: InputProps & FieldComponentProps) {
	return createElement(Input, extractProps(props))
}

export function FormInputCurrency(props: InputCurrencyProps & FieldComponentProps) {
	return createElement(InputCurrency, extractProps(props))
}

export function FormTagInput(props: TagInputProps & FieldComponentProps) {
	return createElement(TagInput, extractProps(props))
}

export function FormTextarea(props: TextareaProps & FieldComponentProps) {
	const { input, meta, ...otherProps } = props
	return <Textarea isInvalid={meta.touched && meta.invalid} {...input} {...otherProps} />
}

export function FormSelect(props: SelectProps<unknown> & FieldComponentProps) {
	return createElement(Select, extractProps(props))
}
