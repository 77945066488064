import clsx from "clsx"
import type { ReactNode } from "react"
import { createContext, useContext, useRef } from "react"
import { DndProvider, useDrag, useDrop } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"

import { useId } from "@utils/id"

import { expect } from "@utils/assert"
import type { TableProps, TableRowProps } from "./table"
import { Table, TableRow } from "./table"

interface DragObject<T> {
	item: T
}

type BaseDndTableRowItem<T extends Record<string | number, unknown> = Record<string | number, unknown>> =
	| {
			id: string
	  }
	| T

interface DndTableRowProps<T extends BaseDndTableRowItem> extends TableRowProps {
	children: ReactNode
	item: T
	getDnDIdFn?: (args: T) => string
}

interface CollectedProps {
	isDragging: boolean
}

interface DndState {
	loading?: boolean
	dragKey: string
	onHover?: (hoveredId: string, itemId: string) => void
	onDrop?: (droppedId: string) => void
	isDraggable?: boolean
}

export const DndContext = createContext<DndState | null>(null)

export interface DndTableProps extends TableProps {
	isDraggable?: boolean
	onHover?: (hoveredId: string, itemId: string) => void
	onDrop?: (droppedId: string) => void
}

/**
 * Use it with DndTableRow and useRankDragAndDrop to get a sortable table
 *
 * @example
 *
 * function Cmp({initialList, moveItemMutation}) {
 *   const [list, handleHover, handleDrop] = useRankDragAndDrop(initialList, moveItemMutation);
 *   return (
 *     <DndTable onDrop={handleDrop} onHover={handleHover}>
 *       <TableBody>
 *         {list.map(item => (
 *           <DndTableRow key={item.id} item={item}>
 *             <TableCell>…</TableCell>
 *           </DndTableRow>
 *         ))}
 *       </TableBody>
 *     </DndTable>
 *   );
 * }
 */
export function DndTable(props: DndTableProps) {
	const { onHover, onDrop, isDraggable = true, ...tableProps } = props
	const dragKey = useId()
	const canDrag = isDraggable && !tableProps.loading

	return (
		<DndProvider backend={HTML5Backend}>
			<DndContext.Provider
				value={{
					loading: tableProps.loading,
					dragKey,
					onHover,
					onDrop,
					isDraggable: canDrag,
				}}
			>
				<Table {...tableProps} />
			</DndContext.Provider>
		</DndProvider>
	)
}

export function DndTableRow<T extends BaseDndTableRowItem>({
	children,
	item,
	getDnDIdFn,
	className,
	...rest
}: DndTableRowProps<T>) {
	const ref = useRef<HTMLTableRowElement>(null)

	const state = expect(useContext(DndContext))
	const canDrag = state.isDraggable && !state.loading
	const [{ isDragging }, connectDrag] = useDrag<DragObject<T>, void, CollectedProps>({
		type: state.dragKey,
		item: { item },
		canDrag,
		collect: (monitor) => ({
			isDragging: monitor.isDragging(),
		}),
	})

	const [, connectDrop] = useDrop<DragObject<T>, void, CollectedProps>({
		accept: state.dragKey,
		hover(hovered) {
			const id = getDnDIdFn?.(item) ?? item.id
			const hovereId = getDnDIdFn?.(hovered.item) ?? hovered.item.id
			if (typeof hovereId !== "string" || typeof id !== "string")
				throw new Error("DndTableRow: id should be a string in hover()")
			if (hovereId !== id) state.onHover?.(hovereId, id)
		},
		drop(dropped) {
			const droppedId = getDnDIdFn?.(dropped.item) ?? dropped.item.id
			if (typeof droppedId !== "string") throw new Error("DndTableRow: id should be a string in drop()")
			state.onDrop?.(droppedId)
		},
	})

	connectDrag(ref)
	connectDrop(ref)

	return (
		<TableRow
			ref={ref}
			className={clsx(className, {
				"hover:cursor-grab": canDrag && !isDragging,
				"cursor-move opacity-0": canDrag && isDragging,
			})}
			{...rest}
		>
			{children}
		</TableRow>
	)
}
