import { Route, Routes } from "react-router-dom"
import { WidgetCreate } from "./widget-create"
import { WidgetEdit } from "./widget-edit"
import { WidgetList } from "./widget-list"

export const WidgetsRoutes = () => {
	return (
		<Routes>
			<Route index element={<WidgetList />} />
			<Route path=":id" element={<WidgetEdit />} />
			<Route path="create" element={<WidgetCreate />} />
		</Routes>
	)
}

export default WidgetsRoutes
