import { Badge } from "@components/badge"
import { StatusLight } from "@components/status-light"
import type { BrandOptionFragment, SeriesEpisodeSearchEntityFragment } from "../../../../graphql/types"
import { ScreenIcon } from "../../../../icons/screen-icon"

interface SeriesListItemProps {
	episode: SeriesEpisodeSearchEntityFragment
	brand: BrandOptionFragment | undefined
}

const SeriesEpisodeListItem = ({ episode, brand }: SeriesListItemProps) => {
	const title = episode.episodeTitle
	const isActiveOnBrand = episode.brandData?.title
	const episodeNumber = episode.number

	return (
		<div className="flex gap-3">
			<div className="flex flex-col gap-1">
				<div className="font-semibold seriess-baseline flex flex-col gap-2 text-wrap">
					<div className="font-semibold seriess-baseline flex  gap-2">
						<span>
							<ScreenIcon className="shrink-0" size="S" />
						</span>
						{brand !== undefined && (
							<span>
								<StatusLight variant={isActiveOnBrand ? "positive" : "negative"} />
							</span>
						)}
					</div>
					<div>
						(Episode {episodeNumber}): {title}{" "}
					</div>
					<Badge>Season {episode.season?.number}</Badge>
					<Badge>Serie: {episode.series.title}</Badge>
				</div>
			</div>
		</div>
	)
}

export default SeriesEpisodeListItem
