import type { ComponentType, HTMLAttributes } from "react"
import { useState } from "react"

import { useHotkey } from "@hooks/use-hotkey"

import { Button } from "./button"
import type { IconPropsWithoutChildren } from "./icon"
import { Modal } from "./modal"

interface DialogProps extends HTMLAttributes<HTMLDivElement> {
	onClose: () => void
	onConfirm: () => void | Promise<void>
	title?: string
	closeLabel?: string
	closeIcon?: ComponentType<IconPropsWithoutChildren>
	confirmLabel?: string
	confirmIcon?: ComponentType<IconPropsWithoutChildren>
	isDestructive: boolean
}

export function Dialog(props: DialogProps) {
	const {
		children,
		title = "Confirm ?",
		closeLabel = "Cancel",
		closeIcon = undefined,
		confirmLabel = "Delete",
		confirmIcon = undefined,
		onClose,
		onConfirm,
		isDestructive,
	} = props
	const [isLoading, setLoading] = useState(false)

	useHotkey("Enter", () => {
		handleConfirm()
	})

	function handleConfirm() {
		if (isLoading) return

		const result = onConfirm()
		if (result instanceof Promise) {
			setLoading(true)
			result.finally(() => {
				setLoading(false)
			})
		}
	}

	return (
		<Modal title={title} onClose={onClose}>
			<div className="rounded-t">
				{children && <div className="break-words mt-4">{children}</div>}
				<div className="flex items-center justify-end mt-3">
					<Button color="transparent" className="ml-1.5" onClick={onClose} icon={closeIcon}>
						{closeLabel}
					</Button>
					<Button
						color={isDestructive ? "danger" : "info"}
						className="ml-1.5"
						loading={isLoading}
						onClick={() => {
							handleConfirm()
						}}
						icon={confirmIcon}
					>
						{confirmLabel}
					</Button>
				</div>
			</div>
		</Modal>
	)
}
