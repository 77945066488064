import { useEffect, useId } from "react"

import { usePreventNav } from "../pages/app/prevent-navigation-ctx"
import { FormChangeState, useFormChangeState } from "./use-form-change-state"

export const usePreventNavigation = (isPreventNavigation: boolean) => {
	const formId = useId()
	const { addForm, updateForm, forms } = usePreventNav()
	const formState = useFormChangeState()

	// biome-ignore lint/correctness/useExhaustiveDependencies: exchaustive deps leads to inifinite re-render there
	useEffect(() => {
		if (isPreventNavigation) {
			const isExist = forms.some((element) => element.id === formId)
			if (isExist) updateForm(formId, formState === FormChangeState.DIRTY)
			else addForm({ id: formId, isDirty: formState === FormChangeState.DIRTY })
		}
	}, [isPreventNavigation, formState])

	return formId
}
