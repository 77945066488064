const yearPartialDateRgx = /^[0-9]{4}$/
const yearMonthPartialDateRgx = /^[0-9]{4}-[0-9]{2}$/
const yearMonthDayPartialDateRgx = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/

export const getCompletedPartialDate = (partialDate: string): string => {
	if (yearMonthDayPartialDateRgx.test(partialDate)) return partialDate
	if (yearMonthPartialDateRgx.test(partialDate)) return `${partialDate}-01`
	if (yearPartialDateRgx.test(partialDate)) return `${partialDate}-01-01`

	throw new Error("getCompletedPartialDate:invalid provided partialDate")
}
