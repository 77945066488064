import type { IconPropsWithoutChildren } from "@components/icon"
import { Icon } from "@components/icon"

export function DragHandleIcon(props: IconPropsWithoutChildren) {
	return (
		<Icon {...props}>
			<svg viewBox="0 0 24 24">
				<path d="M20,9H4v2h16V9z M4,15h16v-2H4V15z" />
			</svg>
		</Icon>
	)
}
