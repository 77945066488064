import * as Sentry from "@sentry/react"
import type { ReactNode } from "react"
import { createContext, useContext } from "react"

import { useViewerQuery } from "@graphql/apollo"
import type { ViewerDataFragment, ViewerPreferencesFragment, ViewerQuery } from "@graphql/types"
import { useSession } from "@utils/auth"

const ViewerContext = createContext<ViewerDataFragment | null>(null)

type ViewerProviderProps = {
	children: ReactNode
}

export const ViewerProvider = (props: ViewerProviderProps) => {
	const { children } = props
	const session = useSession()
	const { data } = useViewerQuery({
		skip: !session,
	})

	if (__ENV__.SENTRY_ENABLED && data?.viewer) {
		const { ref, email, name: username } = data.viewer
		Sentry.setUser({ id: ref, email, username })
	}

	return <ViewerContext.Provider value={data?.viewer ?? null}>{children}</ViewerContext.Provider>
}

export const useViewer = (): ViewerQuery["viewer"] | null => useContext(ViewerContext)

export const useViewerPreferences = (): ViewerPreferencesFragment | null => useContext(ViewerContext)
