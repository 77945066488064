import clsx from "clsx"
import { useCallback, useEffect, useState } from "react"

import { IconButton } from "@components/button"
import { Input } from "@components/input"
import { BlockIcon } from "@icons/block-icon"
import { SaveIcon } from "@icons/save-icon"
import type { InputHTMLAttributes } from "react"

export type ValueType = InputHTMLAttributes<HTMLInputElement>["value"]

export type ReorderButtonGroupProps = {
	disabled?: boolean
	variant?: "tooltip" | "default"
	className?: string
	inputMaxValue: number
	defaultInputValue: number
	onReorder: (rank: number) => void
}

export function ReorderButtonGroup({
	disabled = false,
	inputMaxValue,
	defaultInputValue,
	className,
	variant = "tooltip",
	onReorder,
}: ReorderButtonGroupProps) {
	const [value, setValue] = useState<number>(defaultInputValue)
	const [isTouch, setIsTouch] = useState<boolean>(false)

	useEffect(() => {
		if (value !== undefined && defaultInputValue !== value) setIsTouch(true)
		else setIsTouch(false)
	}, [defaultInputValue, value])

	const onSubmit = useCallback(() => {
		if (typeof value === "number") onReorder(value)
	}, [value, onReorder])

	const inputProps = {
		min: 1,
		max: inputMaxValue,
	}

	return (
		<div
			className={clsx(
				"flex gap-2",
				{
					relative: variant === "tooltip",
				},
				className,
			)}
		>
			<Input
				{...inputProps}
				disabled={disabled}
				className="w-10 text-center"
				type="number"
				value={value}
				onChange={(e) => {
					const parsed = Number.parseInt(e.target.value, 10)
					if (Number.isNaN(parsed) || parsed < inputProps.min || parsed > inputProps.max) return
					setValue(parsed)
				}}
				onKeyDown={(e) => {
					if (e.key === "Enter") onSubmit()
				}}
			/>
			{isTouch && (
				<div
					className={clsx("flex gap-2", {
						...(variant === "tooltip"
							? {
									"absolute z-[1] -right-20 -top-2 p-2 bg-white drop-shadow-xl": true,
								}
							: {}),
					})}
				>
					<IconButton
						disabled={disabled}
						color="danger"
						title="Cancel"
						type="button"
						onClick={() => {
							setValue(defaultInputValue)
						}}
					>
						<BlockIcon />
					</IconButton>
					<IconButton disabled={disabled} color="success" title="Confirm" type="button" onClick={onSubmit}>
						<SaveIcon />
					</IconButton>
				</div>
			)}
		</div>
	)
}
