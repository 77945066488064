import { useCallback, useEffect, useReducer, useState } from "react"
import { Badge } from "../../components/badge"
import { Button, IconButton } from "../../components/button"
import { BoundaryCard, Card, CardHeader, itemPadding } from "../../components/card"
import { FormField } from "../../components/form-field"

import { Input } from "../../components/input"

import { Modal } from "../../components/modal"
import { useNotifications } from "../../components/notification"
import {
	Table,
	TableActionsCell,
	TableBody,
	TableCell,
	TableHead,
	TableHeadCell,
	TableRow,
} from "../../components/table"
import { PermissionTarget } from "../../config/permissionTargets"
import {
	useCreateLocaleMutation,
	useDeleteLocaleMutation,
	useGetLocalesListQuery,
	useUpdateLocaleMutation,
} from "../../graphql/apollo"
import type { GetLocalesListQuery } from "../../graphql/types"
import { useDocumentTitle } from "../../hooks/use-document-title"
import { AddIcon } from "../../icons/add-icon"
import { DeleteIcon } from "../../icons/delete-icon"
import { EditIcon } from "../../icons/edit-icon"
import { useIsGranted } from "../../utils/auth"

import { ISODateTime } from "@components/intl"
import { TablePagination } from "@components/table-pagination"
import { useDebounce } from "@hooks/use-debounce"
import { BlockIcon } from "@icons/block-icon"
import { useStorage, writeToStorage } from "@utils/storage"
import type { Unpack } from "@utils/types"
import clsx from "clsx"
import { EntityMetadataUser } from "pages/shared/components/entity-metadata"
import { type BrandOption, SelectBrand } from "pages/shared/components/select-brand"
import { type CountryOption, SelectCountry } from "pages/shared/components/select-country"
import { type LocalizationOption, SelectLocalization } from "pages/shared/components/select-localization"
import { LocaleIdentityForm, type LocaleIdentityFormValues } from "./components/locale-identity-form"

type ListState = {
	limit: number
	page: number
	search?: string
	country?: CountryOption
	localization?: LocalizationOption | null
	syncFromBrand?: BrandOption
}

type ListAction =
	| { type: "reset" }
	| { type: "limit"; value: number }
	| { type: "page"; value: number }
	| { type: "search"; value: string }
	| { type: "country"; value: CountryOption | undefined }
	| { type: "localization"; value: LocalizationOption | null }
	| { type: "syncFromBrand"; value: BrandOption | undefined }

function reducer(state: ListState, action: ListAction): ListState {
	switch (action.type) {
		case "reset":
			return {
				...state,
				page: 0,
				search: "",
			}
		case "limit":
		case "search":
		case "country":
		case "localization":
		case "syncFromBrand":
		case "page":
			return {
				...state,
				page: 0,
				[action.type]: action.value,
			}
	}
}

const LOCALES_STORAGE_KEY = "locales_filters"
const LOCALES_FILTERS = {
	page: 0,
	search: "",
	limit: 30,
	country: undefined,
	localization: undefined,
	syncFromBrand: undefined,
}

type LocalesFilters = {
	page: number
	search?: string
	limit: number
	country?: CountryOption | undefined
	localization?: LocalizationOption | undefined
	syncFromBrand?: BrandOption | undefined
}

type Locales = Unpack<GetLocalesListQuery>["localesList"]["nodes"]
type Locale = Locales[number]

const Component = () => {
	useDocumentTitle("Locales")

	const { notify } = useNotifications()
	const isGranted = useIsGranted()

	const [localeToEdit, setLocaleToEdit] = useState<Locale | null>(null)
	const [localeToAdd, setLocaleToAdd] = useState(false)
	const [localeToDelete, setLocaleToDelete] = useState<Locale | null>(null)
	const filters = useStorage<LocalesFilters>(LOCALES_STORAGE_KEY, LOCALES_FILTERS)
	const [state, dispatch] = useReducer(reducer, {
		page: filters.page,
		search: filters.search,
		limit: filters.limit,
		country: filters.country,
		localization: filters.localization,
		syncFromBrand: filters.syncFromBrand,
	})
	const [search, { pending: searchDebouncePending }] = useDebounce(state.search)

	const {
		data,
		refetch,
		loading: searchLocalesLoading,
	} = useGetLocalesListQuery({
		skip: searchDebouncePending,
		variables: {
			search,
			limit: state.limit,
			offset: state.limit * state.page,
			countryId: state.country?.id,
			limitedLocalizationId: state.localization?.id,
			syncFromIdBrand: state.syncFromBrand?.id,
		},
	})

	const [createLocale, { loading: createLocaleLoading }] = useCreateLocaleMutation({
		onCompleted: () => {
			notify("success", "Locale created successfully")
			setLocaleToAdd(false)
			refetch()
		},
		onError: () => {
			notify("error", "locale could not be created")
		},
	})

	const [updateLocale, { loading: updateLocaleLoading }] = useUpdateLocaleMutation({
		onCompleted: () => {
			notify("success", "Locale updated successfully")
			setLocaleToEdit(null)
		},
		onError: () => {
			notify("error", "Locale could not be updated")
		},
	})

	const [deleteLocale, { loading: deleteLocaleLoading }] = useDeleteLocaleMutation({
		onCompleted: () => {
			notify("success", "Locale deleted successfully")
			setLocaleToDelete(null)
			refetch()
		},
		onError: () => {
			notify("error", "Locale could not be deleted")
		},
	})
	const locales = data?.localesList?.nodes ?? []

	const onCloseAdd = useCallback(() => setLocaleToAdd(false), [])
	const onCloseEdit = useCallback(() => setLocaleToEdit(null), [])
	const onCloseDelete = useCallback(() => setLocaleToDelete(null), [])

	const onCreateLocale = useCallback(
		async (locale: LocaleIdentityFormValues) => {
			if (localeToAdd) {
				await createLocale({
					variables: {
						input: {
							id: locale.id,
							countryId: locale.country.id,
							syncFromIdBrand: locale.syncFromBrand?.id,
							localizationId: locale.localization?.id,
						},
					},
				})
			}
		},
		[createLocale, localeToAdd],
	)

	const onEditLocale = useCallback(
		async (locale: LocaleIdentityFormValues) => {
			if (localeToEdit) {
				await updateLocale({
					variables: {
						input: {
							id: localeToEdit.id,
							countryId: locale.country.id,
							syncFromIdBrand: locale.syncFromBrand?.id,
							localizationId: locale.localization?.id,
						},
					},
				})
			}
		},
		[updateLocale, localeToEdit],
	)

	useEffect(() => {
		writeToStorage(LOCALES_STORAGE_KEY, {
			page: state.page,
			search: state.search,
			limit: state.limit,
			country: state.country,
			localization: state.localization,
			syncFromBrand: state.syncFromBrand,
		})
	}, [state])

	const loading = searchLocalesLoading || createLocaleLoading || updateLocaleLoading || deleteLocaleLoading
	const canEdit = isGranted(PermissionTarget.STATIC_LOCALE)

	let title = "Locales"
	if (data) title = `${title} (${data.localesList?.totalCount})`

	return (
		<>
			{localeToDelete && (
				<Modal title="Delete locale" onClose={onCloseDelete} className={itemPadding}>
					Are you sure you want to delete selected locale {localeToDelete.name}?
					<div className="flex gap-3 justify-end mt-2">
						<Button color="secondary" icon={BlockIcon} onClick={onCloseDelete}>
							Cancel
						</Button>
						<Button
							onClick={() => {
								deleteLocale({
									variables: {
										input: {
											id: localeToDelete.id,
										},
									},
								})
							}}
							type="submit"
							icon={DeleteIcon}
							color="danger"
							loading={deleteLocaleLoading}
						>
							Submit
						</Button>
					</div>
				</Modal>
			)}
			{localeToEdit && (
				<Modal title="Edit locale" onClose={onCloseEdit} className={itemPadding}>
					<LocaleIdentityForm
						onSubmit={onEditLocale}
						onCancel={onCloseEdit}
						locale={localeToEdit}
						submitButtonLabel="Edit"
						submitButtonIcon={EditIcon}
					/>
				</Modal>
			)}
			{localeToAdd && (
				<Modal title="Add a locale" onClose={onCloseAdd} className={itemPadding}>
					<LocaleIdentityForm
						onSubmit={onCreateLocale}
						onCancel={onCloseAdd}
						submitButtonLabel="Add"
						submitButtonIcon={AddIcon}
					/>
				</Modal>
			)}
			<Card
				header={
					<CardHeader
						title={title}
						actions={
							canEdit && (
								<IconButton
									onClick={() => {
										setLocaleToAdd(!localeToAdd)
									}}
									title="Add a locale"
								>
									<AddIcon />
									Add
								</IconButton>
							)
						}
					/>
				}
			>
				<div className={clsx(itemPadding, "grid grid-cols-4 gap-4")}>
					<FormField label="Search" className={itemPadding}>
						<Input value={state.search} onChange={(e) => dispatch({ type: "search", value: e.target.value })} isClearable />
					</FormField>
					<FormField label="Country">
						<SelectCountry
							isClearable
							isSearchable
							value={state.country}
							onChange={(value) => {
								dispatch({
									type: "country",
									value: value ?? undefined,
								})
							}}
						/>
					</FormField>
					<FormField label="Limited localization">
						<SelectLocalization
							isClearable
							value={state.localization}
							onChange={(value) => {
								dispatch({
									type: "localization",
									value: value,
								})
							}}
						/>
					</FormField>

					<FormField label="Sync From Brands">
						<SelectBrand
							isClearable
							isSearchable
							value={state.syncFromBrand}
							onChange={(value) => {
								dispatch({
									type: "syncFromBrand",
									value: value ?? undefined,
								})
							}}
						/>
					</FormField>
				</div>
				<Table loading={loading}>
					<TableHead>
						<TableHeadCell className="w-24">ID</TableHeadCell>
						<TableHeadCell className="w-28">Country</TableHeadCell>
						<TableHeadCell className="w-28">Limited localization</TableHeadCell>
						<TableHeadCell className="w-32">Sync from Brands</TableHeadCell>
						<TableHeadCell className="w-48">Created at</TableHeadCell>
						<TableHeadCell className="w-32">Created by</TableHeadCell>
						<TableHeadCell className="w-48">Updated at</TableHeadCell>
						<TableHeadCell className="w-32">Updated by</TableHeadCell>
						<TableHeadCell className="w-12">Actions</TableHeadCell>
					</TableHead>
					<TableBody>
						{locales.map((locale) => {
							return (
								<TableRow key={locale.id}>
									<TableCell>{locale.ref}</TableCell>
									<TableCell>{locale.country?.name}</TableCell>
									<TableCell>{locale.limitedLocalization?.name}</TableCell>
									<TableCell>
										<Badge key={locale.syncFromBrand?.ref}>{locale.syncFromBrand?.ref}</Badge>
									</TableCell>
									<TableCell>
										<ISODateTime value={locale.metadata.createdAt} />
									</TableCell>
									<TableCell>
										{locale.metadata.createdBy ? <EntityMetadataUser user={locale.metadata.createdBy} /> : "-"}
									</TableCell>
									<TableCell>
										<ISODateTime value={locale.metadata.updatedAt} />
									</TableCell>
									<TableCell>
										{locale.metadata.updatedBy ? <EntityMetadataUser user={locale.metadata.updatedBy} /> : "-"}
									</TableCell>
									<TableActionsCell gap>
										{canEdit && (
											<>
												<IconButton
													onClick={() => {
														setLocaleToEdit(locale)
													}}
													title="Edit locale"
												>
													<EditIcon />
												</IconButton>
												<IconButton
													onClick={() => {
														setLocaleToDelete(locale)
													}}
													title="Delete locale"
													color="danger"
												>
													<DeleteIcon />
												</IconButton>
											</>
										)}
									</TableActionsCell>
								</TableRow>
							)
						})}
					</TableBody>
				</Table>
				<TablePagination
					className={itemPadding}
					count={data?.localesList.totalCount ?? 0}
					page={state.page}
					rowsPerPage={state.limit}
					onChangePage={(value) => {
						dispatch({ type: "page", value })
					}}
					onChangeRowsPerPage={(value) => {
						dispatch({ type: "limit", value })
					}}
				/>
			</Card>
		</>
	)
}

export const LocalesList = () => {
	return (
		<BoundaryCard>
			<Component />
		</BoundaryCard>
	)
}
