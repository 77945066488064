export interface JWTPayload {
	iat: number
	exp: number
}

export function decodeToken<T extends JWTPayload = JWTPayload>(token: string): T | null {
	const parts = token.split(".")
	if (parts.length !== 3) return null

	const payload = decodeURIComponent(escape(atob(parts[1])))
	try {
		return JSON.parse(payload)
	} catch {
		return null
	}
}
