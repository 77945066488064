import { useNavigate } from "react-router-dom"
import { BoundaryCard, Card } from "../../components/card"
import { useNotifications } from "../../components/notification"
import { useCreateWidgetMutation } from "../../graphql/apollo"
import { useDocumentTitle } from "../../hooks/use-document-title"
import { WidgetIdentityForm } from "./components/widget-identity-form"

const Component = () => {
	useDocumentTitle("Create Widget")
	const { notify } = useNotifications()
	const navigate = useNavigate()

	const [createWidget] = useCreateWidgetMutation({
		onCompleted: () => {
			navigate("/widgets", { replace: true })
			notify("success", "Widget created successfully")
		},
		onError: () => {
			notify("error", "Widget could not be created")
		},
	})

	return (
		<Card header="Create widget">
			<WidgetIdentityForm
				onSubmit={(values) => {
					return createWidget({
						variables: {
							input: {
								name: values.name,
								type: values.type.value,
								url: values.url,
							},
						},
					})
				}}
			/>
		</Card>
	)
}

export const WidgetCreate = () => {
	return (
		<BoundaryCard>
			<Component />
		</BoundaryCard>
	)
}
