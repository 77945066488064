import type { IconPropsWithoutChildren } from "@components/icon"
import { Icon } from "@components/icon"

export function ProxyIcon(props: IconPropsWithoutChildren) {
	return (
		<Icon {...props}>
			<svg viewBox="0 0 24 24">
				<path d="M5,19V15H19v4H5M4,21H20a1,1,0,0,0,1-1V14a1,1,0,0,0-1-1H4a1,1,0,0,0-1,1v6A1,1,0,0,0,4,21Zm13-5.5A1.5,1.5,0,1,1,15.5,17,1.5,1.5,0,0,1,17,15.5ZM19,5V9H5V5H19m1-2H4A1,1,0,0,0,3,4v6a1,1,0,0,0,1,1H20a1,1,0,0,0,1-1V4A1,1,0,0,0,20,3ZM7,8.5A1.5,1.5,0,1,1,8.5,7,1.5,1.5,0,0,1,7,8.5Z" />
			</svg>
		</Icon>
	)
}
