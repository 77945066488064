import { writeToStorage } from "@utils/storage"
import { useEffect } from "react"

type UseWriteToStorage<T> = {
	key: string
	state: T
}
export const useWriteToStorage = <T,>({ key, state }: UseWriteToStorage<T>) => {
	useEffect(() => {
		writeToStorage(key, state)
	}, [state, key])
}
