import { useCallback, useEffect } from "react"

import { os } from "@utils/os"
import { useDebounceCallback } from "usehooks-ts"
import { DEBOUNCE_ULTRA_SHORT_DELAY } from "./use-debounce"

type Options = {
	/* keydown will be used instead of keyup event, default false */
	keydown?: boolean
	/* defines the separator for keys combination, default "+" */
	separator?: string
	debounceDelay?: number
}

enum SpecialKeys {
	Minus = "-",
	Plus = "+",
}

/**
 * Define a comma separated list of hotkeys with either:
 * - a combination of keys (eg: "Alt+s")
 * - one key
 *
 * On MacOS, alt key is automatically replace by ctrl key.
 *
 * @example
 * useHotkeys('Alt+s,ArrowDown', useCallback((event, hotkey) => {
 *  if(hotkey === "ArrowDown") {
 *    // the arrow down key was pressed
 *  } else if (hotkey === "Alt+s") {
 *    // Alt (or ctrl on MacOs) and "s" keys were pressed
 *  }
 * }), []);
 */
export const useHotkey = (
	hotkeysList: string | string[],
	callback: (event: KeyboardEvent, hotkey: string) => void,
	options: Partial<Options> = {},
) => {
	const { keydown = false, separator = "+", debounceDelay = DEBOUNCE_ULTRA_SHORT_DELAY } = options

	// keys presse are accumulated in a set, flushed at every periods
	// this prevent requirement of exact order of keys pressed
	// and allow just a combination set of required keys
	const debouncedTrigger = useDebounceCallback((cb) => cb(), debounceDelay)

	const hotkeys = Array.isArray(hotkeysList) ? hotkeysList : hotkeysList.split(",")
	const keysPressed = new Set<string>()
	const hotkeysMap = new Map<string, string[]>()

	const triggerHotkeys = useCallback(
		(e: KeyboardEvent) => {
			debouncedTrigger(() => {
				const pressed = [...keysPressed]
				keysPressed.clear()
				const hotkEntries = hotkeysMap.entries()
				for (const [hotkey, parts] of hotkEntries) {
					const hasCombination = pressed.every((key) => parts.includes(key)) && pressed.length === parts.length
					if (!hasCombination) continue
					callback(e, hotkey)
					break
				}
			})
		},
		[callback, debouncedTrigger, hotkeysMap, keysPressed],
	)

	const handleKeydown = useCallback(
		(e: KeyboardEvent) => {
			keysPressed.add(e.key)
			if (keydown) triggerHotkeys(e)
		},
		[triggerHotkeys, keysPressed, keydown],
	)

	const handleKeyup = useCallback(
		(e: KeyboardEvent) => {
			if (!keydown) triggerHotkeys(e)
		},
		[triggerHotkeys, keydown],
	)

	for (const hotkey of hotkeys) {
		const parsedParts = hotkey.split(separator)

		// handle special hotkeys that uses the separator as a combination, like +1 or -2
		const parts = parsedParts.map((p) => {
			const special = SpecialKeys[p as keyof typeof SpecialKeys]
			if (special) return special
			return p
		})

		hotkeysMap.set(
			hotkey,
			parts.map((p) => (p === "Alt" && os() === "MacOS" ? "Control" : p)),
		)
	}

	useEffect(() => {
		window.addEventListener("keydown", handleKeydown)
		window.addEventListener("keyup", handleKeyup)

		return () => {
			window.removeEventListener("keydown", handleKeydown)
			window.removeEventListener("keyup", handleKeyup)
		}
	}, [handleKeydown, handleKeyup])
}
