import { getGlobalEntityTitleAttrs } from "@utils/global-titles"
import type { Params } from "react-router-dom"
import { BreadCrumbLabel } from "../../components/breadcrumbs"
import { useEpisodeEntity } from "./hooks/series-episode"

export const EpisodeBreadCrumbTitle = ({ match }: { match: Params<string> }) => {
	if (!match.episode) throw new Error("EpisodeBreadCrumbTitle:requirements not met")
	const { episode } = useEpisodeEntity(match.episode)
	const titleAttrs = episode ? getGlobalEntityTitleAttrs(episode) : undefined
	return <BreadCrumbLabel label={titleAttrs?.minshortLabel} copyable />
}
