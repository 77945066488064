import { useMemo } from "react"

import type { SelectProps } from "@components/select"
import { Select } from "@components/select"
import { useAllowedBrandOptionsQuery, useAllowedViewerBrandOptionsQuery, useBrandOptionsQuery } from "@graphql/apollo"
import type { BrandOptionFragment } from "@graphql/types"

export type BrandOption = BrandOptionFragment

export function SelectBrand<IsMulti extends boolean = false>(props: SelectProps<BrandOption, IsMulti>) {
	const { invalid, ...rest } = props
	const { data, loading } = useBrandOptionsQuery({
		fetchPolicy: "cache-first",
	})

	const options = useMemo(() => {
		if (data?.brands) return data.brands
		return []
	}, [data])

	return (
		<Select
			{...rest}
			invalid={invalid}
			isLoading={loading}
			options={options}
			getOptionValue={(brand) => brand.id}
			getOptionLabel={(brand) => brand.name}
		/>
	)
}

export function SelectAllowedBrand<IsMulti extends boolean = false>(
	props: SelectProps<BrandOption, IsMulti> & { userId?: string },
) {
	const { invalid, userId, ...rest } = props
	let { isClearable } = props
	const userResult = useAllowedBrandOptionsQuery({
		fetchPolicy: "cache-first",
		skip: userId == null,
		variables:
			userId == null
				? undefined
				: {
						id: userId,
					},
	})

	const viewerResult = useAllowedViewerBrandOptionsQuery({
		fetchPolicy: "cache-first",
		skip: userId != null,
	})

	const options = useMemo(() => {
		if (userResult.data) return userResult.data.user?.allowedBrands
		if (viewerResult.data) return viewerResult.data.viewer.allowedBrands
		return []
	}, [userResult, viewerResult])

	// isClearable computed from the number of allowed brands and the number of brands
	// clearable if the user can list all brands
	if (!(typeof isClearable === "boolean"))
		isClearable = (viewerResult.data?.brands?.length ?? 0) === (options?.length ?? 0)

	return (
		<Select
			{...rest}
			isClearable={isClearable}
			invalid={invalid}
			isLoading={userResult.loading || viewerResult.loading}
			options={options}
			getOptionValue={(brand) => brand.id}
			getOptionLabel={(brand) => brand.name}
		/>
	)
}
