import { useGetSeasonMetadataQuery } from "@graphql/apollo"
import type { NoEmpty } from "@utils/types"

export const useSeasonEntity = (seasonRef: string) => {
	if (!seasonRef) throw new Error("useSeasonEntity: mising ref parameter")

	const { data, loading } = useGetSeasonMetadataQuery({
		fetchPolicy: "cache-and-network",
		variables: {
			ref: seasonRef,
		},
	})

	const entity = data?.entityByRef
	const seriesSeason = entity as NoEmpty<typeof entity>
	const isCorrectEntity = seriesSeason?.__typename === "SeriesSeason"

	return {
		loading,
		season: isCorrectEntity ? seriesSeason : undefined,
	}
}
