import type { RefObject } from "react"
import { useEffect } from "react"

import { useEvent } from "./use-event"

export function useOnClickOutside<T extends HTMLElement>(ref: RefObject<T>, handleClickOutside: () => void) {
	const onClickOutside = useEvent((event: MouseEvent) => {
		const currentRef = ref.current
		if (currentRef && !currentRef.contains(event.target as Node)) handleClickOutside()
	})

	useEffect(() => {
		document.addEventListener("mousedown", onClickOutside)
		return () => {
			document.removeEventListener("mousedown", onClickOutside)
		}
	}, [onClickOutside])

	return ref
}
