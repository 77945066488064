import clsx from "clsx"
import { useState } from "react"
import { LinkIcon } from "../icons/link-icon"
import { OpenInNewIcon } from "../icons/open-in-new-icon"

export const ExternalLink = ({
	url,
	label,
	className,
	variant = "default",
	...rest
}: { url: string; label?: string; className?: string; variant?: "default" | "over" }) => {
	const [iconVisible, setIconVisible] = useState(false)
	return (
		<a
			href={url}
			target="_blank"
			rel="noopener noreferrer"
			className={clsx("inline-block align-middle hover:underline focus:underline", className)}
			{...(variant === "over"
				? {
						onPointerEnter: () => {
							setIconVisible(true)
						},
						onFocus: () => {
							setIconVisible(true)
						},
						onPointerLeave: () => {
							setIconVisible(false)
						},
					}
				: {})}
			{...rest}
		>
			{variant === "default" && <LinkIcon className="mr-1" />}
			{label ?? url}
			{variant === "over" && (
				<OpenInNewIcon
					className={clsx("ml-1", {
						visible: iconVisible,
						hidden: !iconVisible,
					})}
				/>
			)}
		</a>
	)
}
