import { getGlobalEntityTitleAttrs } from "@utils/global-titles"
import type { Params } from "react-router-dom"
import { BreadCrumbLabel } from "../../components/breadcrumbs"
import { useSeasonEntity } from "./hooks/series-season"

export const SeasonBreadCrumbTitle = ({ match }: { match: Params<string> }) => {
	if (!match.season) throw new Error("SeasonBreadCrumbTitle:requirements not met")

	const { season } = useSeasonEntity(match.season)
	const titleAttrs = season ? getGlobalEntityTitleAttrs(season) : undefined
	return <BreadCrumbLabel label={titleAttrs?.minshortLabel} copyable />
}
