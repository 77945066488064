import { prettyPrintByteSize } from "./formatters"
import {
	IMG_DEFAULT_DIMENSIONS_RANGE,
	IMG_FILE_SIZE_LIMIT,
	type ImageDimensionsRange,
	isImageDimensionsRange,
} from "./image"

export const imageDimensionsRangePlainValidator = (img: HTMLImageElement, limitsRange: ImageDimensionsRange) => {
	if (img.width === 0 || img.height === 0) throw new Error("Invalid empty image")

	const { min = { width: 0, height: 0 }, exact, max = IMG_DEFAULT_DIMENSIONS_RANGE.max } = limitsRange
	if (!isImageDimensionsRange(limitsRange)) throw new Error("Invalid provided limits range to validate")

	if (!(min.width <= img.width && img.width <= max.width))
		throw new Error(
			`Width must be ${min.width === max.width ? `of ${min.width}` : `between ${min.width} and ${max.width}`}px`,
		)

	if (!(min.height <= img.height && img.height <= max.height))
		throw new Error(
			`Height must be ${min.height === max.height ? `of ${min.height}` : `between ${min.height} and ${max.height}`}px`,
		)

	if (exact) {
		if (exact.width !== img.width && exact.height !== img.height)
			throw new Error(`Image must be of ${exact.width}x${exact.height}px`)
	}
}

// biome-ignore lint/suspicious/noExplicitAny: no possible to know the type of a and b in advance
export const isEqual = (a: any, b: any) => JSON.stringify(a) === JSON.stringify(b)

// biome-ignore lint/suspicious/noExplicitAny: can be anything there
export const maxFileSizePlainValidator = async (value: any, limit: number = IMG_FILE_SIZE_LIMIT) => {
	let size: number | undefined
	if (value instanceof File && value.size > limit) size = value.size
	else if (value instanceof HTMLImageElement) {
		const resp = await fetch(value.src)
		const blob = await resp.blob()
		size = blob.size
	}
	if (size !== undefined && size > limit)
		throw new Error(`File too large, maximum size is ${prettyPrintByteSize(limit)}`)
}

// biome-ignore lint/suspicious/noExplicitAny: value can be anything
export const maxFileSize = async (value: any, limit: number = IMG_FILE_SIZE_LIMIT) => {
	try {
		await maxFileSizePlainValidator(value, limit)
	} catch (e) {
		if (e instanceof Error) return e.message
		throw e
	}
	return undefined
}
