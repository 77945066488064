import clsx from "clsx"

import { TableCell } from "@components/table"
import { DragHandleIcon } from "@icons/drag-handle-icon"

import { useContext } from "react"
import { DndContext } from "../../../components/dnd-table"
import { expect } from "../../../utils/assert"
import { isDefined } from "../../../utils/types"
import type { ReorderButtonGroupProps } from "./reorder-button-group"
import { ReorderButtonGroup } from "./reorder-button-group"

export type ReorderRankProps = {
	variant?: "input" | "text"
	disabled?: boolean
	rank: number
	canReorder?: boolean
	canReorderDnD?: boolean
	displayDragHandle?: boolean
	displayButtonGroup?: boolean
	buttonGroupProps?: Partial<ReorderButtonGroupProps>
	inputMaxValue?: number
	className?: string
} & Pick<ReorderButtonGroupProps, "onReorder">

export const ReorderRank = ({
	variant = "input",
	disabled: pDisabled,
	canReorder = true,
	canReorderDnD: pCanReorderDnD,
	displayDragHandle: pDisplayDragHandle,
	rank,
	displayButtonGroup = true,
	onReorder,
	inputMaxValue = Number.POSITIVE_INFINITY,
	buttonGroupProps = {},
}: ReorderRankProps) => {
	const state = expect(useContext(DndContext))
	const canReorderDnD = isDefined(pCanReorderDnD) ? pCanReorderDnD : state.isDraggable || state.loading
	const btnGroupEnabled = displayButtonGroup
	const shouldBeDisabled = !(canReorder || canReorderDnD) || state.loading
	const disabled = isDefined(pDisabled) ? pDisabled : shouldBeDisabled
	const displayDragHandle = isDefined(pDisplayDragHandle) ? pDisplayDragHandle : canReorderDnD
	const isTextOnly = variant === "text" || !(btnGroupEnabled && variant === "input")

	return (
		<div
			className={clsx({
				"text-center": isTextOnly,
				"flex gap-2 items-center": btnGroupEnabled,
				"flex gap-1 ml-2": variant === "text",
			})}
		>
			{displayDragHandle && (
				<DragHandleIcon
					disabled={disabled}
					className={clsx({
						"mx-1": btnGroupEnabled,
						"mx-2": !btnGroupEnabled,
					})}
				/>
			)}
			{btnGroupEnabled && variant === "input" ? (
				<ReorderButtonGroup
					disabled={disabled}
					inputMaxValue={inputMaxValue}
					defaultInputValue={rank}
					onReorder={onReorder}
					{...buttonGroupProps}
				/>
			) : (
				rank
			)}
		</div>
	)
}

export const ReorderRankCell = (props: ReorderRankProps) => (
	<TableCell className={props.className}>
		<ReorderRank {...props} />
	</TableCell>
)
