import { useGetSeriesMetadataQuery } from "@graphql/apollo"
import type { NoEmpty } from "@utils/types"

export const useSeriesEntity = (seriesRef: string) => {
	if (!seriesRef) throw new Error("useSeriesEntity: mising ref parameter")

	const { data, loading } = useGetSeriesMetadataQuery({
		fetchPolicy: "cache-and-network",
		variables: {
			ref: seriesRef,
		},
	})

	const entity = data?.entityByRef
	const series = entity as NoEmpty<typeof entity>
	const isCorrectEntity = series?.__typename === "Series"

	return {
		loading,
		series: isCorrectEntity ? series : undefined,
	}
}
