import { createContext } from "react"

import type { ReactNode } from "react"
import { useNotifications } from "../../../components/notification"
import { useGetFestivalDefaultLocaleQuery } from "../../../graphql/apollo"
import type { GetFestivalDefaultLocaleQuery } from "../../../graphql/types"
import { NotFound } from "../../errors/not-found"

/**
 * All of the festival locale related code below is a temporary way to handle locale
 * and should be remove in the future
 * Ideally, it should be handled from user preference.
 */
export type FestivalLocale = GetFestivalDefaultLocaleQuery["festivalDefaultLocale"]

export const FestivalLocaleContext = createContext<FestivalLocale | undefined>(undefined)

export const FestivalLocaleProvider = ({
	children,
}: {
	children: ReactNode
}) => {
	const { notify } = useNotifications()
	const { data, loading } = useGetFestivalDefaultLocaleQuery({
		onError: () => {
			notify("error", "Failed to fetch festival locale")
		},
	})
	const locale = data?.festivalDefaultLocale

	if (loading) return null
	if (!locale) return <NotFound />

	return <FestivalLocaleContext.Provider value={locale}>{children}</FestivalLocaleContext.Provider>
}
