import { useMediaQuery } from "usehooks-ts"

export const useMinSM = () => useMediaQuery("(min-width: 640px)")

export const useMinMD = () => useMediaQuery("(min-width: 768px)")

export const useMinLG = () => useMediaQuery("(min-width: 1024px)")

export const useMinXL = () => useMediaQuery("(min-width: 1280px)")

export const useMin2XL = () => useMediaQuery("(min-width: 1536px)")
