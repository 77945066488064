import type { IconPropsWithoutChildren } from "@components/icon"
import { Icon } from "@components/icon"

export function OpeSpeIcon(props: IconPropsWithoutChildren) {
	return (
		<Icon {...props}>
			<svg viewBox="0 0 24 24">
				<path d="M23,11.01,18,11a1,1,0,0,0-1,1v9a1,1,0,0,0,1,1h5a1,1,0,0,0,1-1V12A.994.994,0,0,0,23,11.01ZM23,20H18V13h5ZM20,2H2A1.993,1.993,0,0,0,0,4V16a2,2,0,0,0,2,2H9v2H7v2h8V20H13V18h2V16H2V4H20V9h2V4A2,2,0,0,0,20,2ZM11.97,9,11,6l-.97,3H7l2.47,1.76-.94,2.91L11,11.87l2.47,1.8-.94-2.91L15,9Z" />
			</svg>
		</Icon>
	)
}
