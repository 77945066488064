import { useCallback, useMemo, useState } from "react"
import { Badge } from "../../components/badge"
import { IconButton } from "../../components/button"
import { ButtonLink } from "../../components/button-link"
import { BoundaryCard, Card, CardHeader, itemPadding } from "../../components/card"
import { FormField } from "../../components/form-field"
import { GlobalEntityLink } from "../../components/global-entity-link"
import { Input } from "../../components/input"
import { ISODateTime } from "../../components/intl"
import { Modal } from "../../components/modal"
import { useNotifications } from "../../components/notification"
import {
	LinkableTableCell,
	Table,
	TableActionsCell,
	TableBody,
	TableCell,
	TableHead,
	TableHeadCell,
	TableRow,
} from "../../components/table"
import { PermissionTarget } from "../../config/permissionTargets"
import { useDeleteWidgetMutation, useSearchWidgetsQuery, useUpdateWidgetMutation } from "../../graphql/apollo"
import type { WidgetIdentityFragment } from "../../graphql/types"
import { useDocumentTitle } from "../../hooks/use-document-title"
import { AddIcon } from "../../icons/add-icon"
import { DeleteIcon } from "../../icons/delete-icon"
import { EditIcon } from "../../icons/edit-icon"
import { useIsGranted } from "../../utils/auth"
import { EntityMetadataUser } from "../shared/components/entity-metadata"
import { WidgetIdentityForm, type WidgetIdentityFormValues } from "./components/widget-identity-form"

const Component = () => {
	useDocumentTitle("Widgets")

	const { notify } = useNotifications()
	const isGranted = useIsGranted()
	const [search, setSearch] = useState("")
	const [widgetToEdit, setWidgetToEdit] = useState<WidgetIdentityFragment | null>(null)

	const {
		data,
		refetch,
		loading: searchWidgetsLoading,
	} = useSearchWidgetsQuery({
		variables: {
			offset: 0,
			search,
		},
	})

	const [deleteWidget, { loading: deleteWidgetLoading }] = useDeleteWidgetMutation({
		onCompleted: () => {
			notify("success", "Widget deleted successfully")
			refetch()
		},
		onError: () => {
			notify("error", "Widget could not be deleted")
		},
	})

	const [udpateWidget, { loading: updateWidgetLoading }] = useUpdateWidgetMutation({
		onCompleted: () => {
			notify("success", "Widget updated successfully")
			setWidgetToEdit(null)
		},
		onError: () => {
			notify("error", "Widget could not be updated")
		},
	})

	const widgets = useMemo(() => data?.widgets.nodes ?? [], [data])

	const onClose = useCallback(() => setWidgetToEdit(null), [])

	const onEditWidget = useCallback(
		async (widget: WidgetIdentityFormValues) => {
			if (widgetToEdit) {
				await udpateWidget({
					variables: {
						input: {
							widgetId: widgetToEdit.id,
							name: widget.name,
							type: widget.type.value,
							url: widget.url,
						},
					},
				})
			}
		},
		[udpateWidget, widgetToEdit],
	)

	const loading = searchWidgetsLoading || deleteWidgetLoading || updateWidgetLoading

	return (
		<>
			{widgetToEdit && (
				<Modal title="Edit widget" onClose={onClose} className={itemPadding}>
					<WidgetIdentityForm
						onSubmit={onEditWidget}
						onCancel={onClose}
						widget={widgetToEdit}
						submitButtonLabel="Edit"
						submitButtonIcon={EditIcon}
					/>
				</Modal>
			)}
			<Card
				header={
					<CardHeader
						title="Widgets"
						actions={
							isGranted(PermissionTarget.WIDGET) && (
								<ButtonLink to="/widgets/create" icon={AddIcon}>
									Add
								</ButtonLink>
							)
						}
					/>
				}
			>
				<FormField className={itemPadding}>
					<Input value={search} onChange={(e) => setSearch(e.target.value)} isClearable={true} />
				</FormField>
				<Table loading={loading}>
					<TableHead>
						<TableHeadCell className="w-12">#ID</TableHeadCell>
						<TableHeadCell className="w-24">Type</TableHeadCell>
						<TableHeadCell>Name</TableHeadCell>
						<TableHeadCell>Url</TableHeadCell>
						<TableHeadCell className="w-48">Created at</TableHeadCell>
						<TableHeadCell>Created by</TableHeadCell>
						<TableHeadCell />
					</TableHead>
					<TableBody>
						{widgets.map((widget) => {
							return (
								<TableRow key={widget.id}>
									<TableCell>
										<GlobalEntityLink entity={widget} labelType="refLabel" />
									</TableCell>
									<TableCell>
										<Badge>{widget.type}</Badge>
									</TableCell>
									<TableCell>
										<GlobalEntityLink entity={widget} labelType="entityLabel" />
									</TableCell>
									{widget.url ? <LinkableTableCell url={widget.url} /> : <TableCell />}
									<TableCell>
										<ISODateTime value={widget.metadata.createdAt} />
									</TableCell>
									<TableCell truncate>
										<EntityMetadataUser user={widget.metadata.createdBy} />
									</TableCell>
									<TableActionsCell gap>
										<IconButton
											onClick={() => {
												setWidgetToEdit(widget)
											}}
											title="Edit widget"
										>
											<EditIcon />
										</IconButton>
										<IconButton
											onClick={() =>
												deleteWidget({
													variables: {
														input: {
															widgetId: widget.id,
														},
													},
												})
											}
											title="Delete"
											color="danger"
										>
											<DeleteIcon />
										</IconButton>
									</TableActionsCell>
								</TableRow>
							)
						})}
					</TableBody>
				</Table>
			</Card>
		</>
	)
}

export const WidgetList = () => {
	return (
		<BoundaryCard>
			<Component />
		</BoundaryCard>
	)
}
