import { useEffect, useLayoutEffect, useState } from "react"

const removeHashCharacter = (str: string) => str.slice(1)

type ScrollToHashElementProps = {
	timeout?: number
	checkElementInterval?: number
	elementOffset?: number
}

const ScrollToHashElement = ({
	timeout = 5000,
	checkElementInterval = 200,
	elementOffset = -100,
}: ScrollToHashElementProps) => {
	const [hash, setHash] = useState(window.location.hash)

	const originalPushState = window.history.pushState
	const originalReplaceState = window.history.replaceState

	// biome-ignore lint/suspicious/noExplicitAny: too variadic
	window.history.pushState = function (...args: any) {
		const result = originalPushState.apply(this, args)
		window.dispatchEvent(new Event("pushstate"))
		window.dispatchEvent(new Event("locationchange"))
		return result
	}

	// biome-ignore lint/suspicious/noExplicitAny: too variadic
	window.history.replaceState = function (...args: any) {
		const result = originalReplaceState.apply(this, args)
		window.dispatchEvent(new Event("replacestate"))
		window.dispatchEvent(new Event("locationchange"))
		return result
	}

	window.addEventListener("popstate", () => window.dispatchEvent(new Event("locationchange")))

	useEffect(() => {
		const handleLocationChange = () => setHash(window.location.hash)
		window.addEventListener("locationchange", handleLocationChange)
		return () => {
			window.removeEventListener("locationchange", handleLocationChange)
		}
	}, [])

	useLayoutEffect(() => {
		if (!hash) return

		let element: HTMLElement | null | undefined
		const elementId = removeHashCharacter(hash)

		const cleanupWatchers = () => {
			observer.disconnect()
			clearInterval(elementCheckerId)
			clearInterval(elementNavigatorId)
		}

		const observer = new IntersectionObserver((entries) => {
			if (!element) return
			for (const entry of entries) {
				window.scrollTo({
					behavior: "auto",
					top: element.getBoundingClientRect().top - document.body.getBoundingClientRect().top + elementOffset,
				})
				if (!entry.isIntersecting) cleanupWatchers()
			}
		})

		const elementCheckerId = setInterval(() => {
			element = document.getElementById(elementId)
			if (!element) return
			clearInterval(elementCheckerId)
		}, checkElementInterval)

		const elementNavigatorId = setInterval(() => {
			if (!element) return

			observer.observe(element)
			clearInterval(elementNavigatorId)
		}, checkElementInterval)

		const elementCheckTimeoutId = setTimeout(cleanupWatchers, Math.max(timeout, checkElementInterval))

		return () => {
			cleanupWatchers()
			clearTimeout(elementCheckTimeoutId)
		}
	}, [hash, timeout, checkElementInterval, elementOffset])

	return null
}

export default ScrollToHashElement
