import clsx from "clsx"
import type { ElementType, ReactNode } from "react"

export interface LabelProps {
	children: ReactNode
	for?: string
	isRequired?: boolean
	as?: ElementType
	className?: string
}

export function Label(props: LabelProps) {
	const { children, isRequired = false, for: htmlFor, as: ElementType = "label", className } = props
	return (
		<ElementType
			className={clsx("flex items-center text-sm text-gray-500 whitespace-nowrap", className)}
			htmlFor={ElementType === "label" ? htmlFor : undefined}
		>
			{children}
			{isRequired && <span className="ml-1 leading-none text-base select-none">*</span>}
		</ElementType>
	)
}
