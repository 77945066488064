import { Button } from "@components/button"
import { BlockIcon } from "@icons/block-icon"
import { DeleteIcon } from "@icons/delete-icon"

type CertificateDeleteProps = {
	onSubmit: () => void
	onCancel?: () => void
}

export const CertificateDelete = ({ onSubmit, onCancel }: CertificateDeleteProps) => {
	return (
		<>
			Are you sure you want to delete selected certificate?
			<div className="flex gap-3 justify-end mt-2">
				{onCancel !== undefined && (
					<Button color="secondary" icon={BlockIcon} onClick={onCancel}>
						Cancel
					</Button>
				)}
				<Button onClick={onSubmit} color="danger" type="submit" icon={DeleteIcon}>
					Delete
				</Button>
			</div>
		</>
	)
}
