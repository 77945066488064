import type { IconPropsWithoutChildren } from "@components/icon"
import { Icon } from "@components/icon"

export function MatchingIcon(props: IconPropsWithoutChildren) {
	return (
		<Icon {...props}>
			<svg viewBox="0 0 24 24">
				<path d="M6.99,11,3,15l3.99,4V16H14V14H6.99ZM21,9,17.01,5V8H10v2h7.01v3Z" />
			</svg>
		</Icon>
	)
}
