import { useContext } from "react"
import { useOutletContext } from "react-router-dom"

import { useGetFestivalMetadataQuery } from "@graphql/apollo"

import { getGlobalEntityTitleAttrs } from "../../../utils/global-titles"
import { FestivalLocaleContext } from "../providers/festival"

type ContextType = {
	festivalId: string
	festivalRef: string
}

export const useFestivalOutlet = () => useOutletContext<ContextType>()

export const useFestivalLocale = () => {
	const ctx = useContext(FestivalLocaleContext)
	if (!ctx) throw new Error("useFestivalLocale: context not properly initialized with the appropriate provider")

	return ctx
}

export const useFestivalEntity = (festivalRef: string) => {
	const locale = useFestivalLocale()
	if (!festivalRef) throw new Error("useFestivalEntity: mising ref parameter")

	const { data, loading } = useGetFestivalMetadataQuery({
		skip: !(locale && festivalRef),
		fetchPolicy: "cache-and-network",
		variables: {
			ref: festivalRef,
			locale: locale.id,
		},
	})

	const entity = data?.entityByRef
	const isCorrectEntity = entity?.__typename === "Festival"

	const titleAttrs = isCorrectEntity ? getGlobalEntityTitleAttrs(entity) : undefined
	return {
		loading,
		festival: isCorrectEntity ? entity : undefined,
		titleAttrs,
	}
}
