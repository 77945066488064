import { createContext, useContext, useMemo } from "react"

interface IDContextValue {
	current: number
}

const defaultContext: IDContextValue = {
	current: 0,
}

const IDContext = createContext(defaultContext)

/**
 * If not provided, generate a unique ID.
 *
 * @example
 * // Generate a unique ID
 * const id = useId(); // e.g. id-123
 *
 * @example
 * // Use `props.id` is provided, otherwise generate an ID.
 * const id = useId(props.id);
 */
export function useId(defaultId?: string): string {
	const provider = useContext(IDContext)
	const id = useMemo(() => defaultId ?? `id-${++provider.current}`, [provider, defaultId])

	return id
}
