import type { SelectProps } from "@components/select"
import { Select } from "@components/select"
import { Currency } from "@graphql/types"

export type CurrencyOption = {
	label: string
	value: Currency
}

const options: CurrencyOption[] = Object.values(Currency).map((currency) => ({
	value: currency,
	label: currency,
}))

export const stringToCurrency = (value: string | null | undefined): Currency | null => {
	if (!value) return null
	if (Object.values(Currency).includes(value as Currency)) return value as Currency
	return null
}

export function SelectCurrency<IsMulti extends boolean = false>(props: SelectProps<CurrencyOption, IsMulti>) {
	return <Select {...props} options={options} />
}
