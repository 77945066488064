import type { CountryOption } from "pages/shared/components/select-country"
import type { LocalizationOption } from "pages/shared/components/select-localization"

type ListState = {
	limit: number
	page: number
	search?: string
	country?: CountryOption
	localization?: LocalizationOption | null
}

type ListAction =
	| { type: "reset" }
	| { type: "limit"; value: number }
	| { type: "page"; value: number }
	| { type: "search"; value: string }
	| { type: "country"; value: CountryOption | undefined }
	| { type: "localization"; value: LocalizationOption | null }

export const filtersReducer = (state: ListState, action: ListAction): ListState => {
	switch (action.type) {
		case "reset":
			return { ...state, page: 0, search: "" }
		case "limit":
		case "search":
		case "country":
		case "localization":
		case "page":
			return {
				...state,
				page: 0,
				[action.type]: action.value,
			}
	}
}
