import { type ComponentType, useMemo } from "react"
import { Field, Form } from "react-final-form"
import { Button } from "../../../components/button"
import { FieldError, FormInput } from "../../../components/form"
import { FormField } from "../../../components/form-field"
import type { IconPropsWithoutChildren } from "../../../components/icon"

import { BlockIcon } from "../../../icons/block-icon"
import { SaveIcon } from "../../../icons/save-icon"

import type { BrandOptionFragment, CountryOptionFragment, LocaleIdentityFragment } from "@graphql/types"
import { isNotEmpty, isValidLocale } from "@utils/validators"
import { SelectBrand } from "pages/shared/components/select-brand"
import { SelectCountry } from "pages/shared/components/select-country"
import { type LocalizationOption, SelectLocalization } from "pages/shared/components/select-localization"

type LocaleIdentityFormProps = {
	onCancel?: () => void
	onSubmit: (values: LocaleIdentityFormValues) => void
	locale?: LocaleIdentityFragment
	submitButtonLabel?: string
	submitButtonIcon?: ComponentType<IconPropsWithoutChildren>
}

export type LocaleIdentityFormValues = {
	id: string
	syncFromBrand: BrandOptionFragment | null
	country: CountryOptionFragment
	localization: LocalizationOption | null
}

export const LocaleIdentityForm = ({
	onCancel,
	onSubmit,
	locale,
	submitButtonLabel = "Save",
	submitButtonIcon = SaveIcon,
}: LocaleIdentityFormProps) => {
	const initialValues = useMemo(
		() =>
			locale
				? {
						id: locale.ref,
						country: locale.country,
						localization: locale.limitedLocalization,
						syncFromBrand: locale.syncFromBrand,
					}
				: undefined,
		[locale],
	)

	return (
		<Form<LocaleIdentityFormValues>
			initialValues={initialValues}
			onSubmit={onSubmit}
			subscription={{
				submitting: true,
				values: true,
			}}
		>
			{({ handleSubmit, submitting, values, form }) => (
				<form onSubmit={handleSubmit} className="flex flex-col gap-2 p-2">
					<FormField label="ID" isRequired className="w-1/2">
						<Field
							name="id"
							component={FormInput}
							validate={(value) => isNotEmpty(value) || isValidLocale(value)}
							disabled={Boolean(locale)}
						/>

						<FieldError name="id" />
					</FormField>
					<div className="grid grid-cols-2 gap-4">
						<FormField label="Country" isRequired>
							<Field
								name="country"
								validate={isNotEmpty}
								render={({ input, meta }) => (
									<SelectCountry
										{...input}
										invalid={meta.touched && meta.invalid}
										isClearable
										onChange={(country) => {
											form.change("localization", null)
											input.onChange(country ?? null)
										}}
									/>
								)}
							/>
							<FieldError name="country" />
						</FormField>
						<FormField label="localization">
							<Field
								name="localization"
								render={({ input, meta }) => (
									<SelectLocalization
										{...input}
										countryId={values.country?.id}
										invalid={meta.touched && meta.invalid}
										isClearable
										isDisabled={!values.country?.id}
										onChange={(localization) => {
											form.change("localization", localization ?? undefined)
										}}
									/>
								)}
							/>
							<FieldError name="localization" />
						</FormField>
					</div>
					<FormField label="Sync from Brand" className="w-1/2">
						<Field
							name="syncFromBrand"
							render={({ input, meta }) => (
								<SelectBrand
									{...input}
									invalid={meta.touched && meta.invalid}
									isClearable
									onChange={(brand) => {
										input.onChange(brand)
									}}
								/>
							)}
						/>
						<FieldError name="syncFromBrand" />
					</FormField>
					<div className="flex gap-4 justify-end mt-2">
						{Boolean(onCancel) && (
							<Button color="secondary" icon={BlockIcon} onClick={onCancel}>
								Cancel
							</Button>
						)}
						<Button type="submit" icon={submitButtonIcon} loading={submitting}>
							{submitButtonLabel}
						</Button>
					</div>
				</form>
			)}
		</Form>
	)
}
